#HealthCheck-container {
    padding: 30px 40px;
    height: calc(100vh - 60px);
    overflow-x: hidden;
}

#HealthCheck-title {
    font-size: 40px;
    margin: 0;
    margin-left: 15px;
}

#HealthCheck-title-container {
    border-bottom: 1px solid #4b4f4f;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    align-items: baseline;
}

#HealthCheck-title-container-buttons-div {
    display: flex;
    align-items: center;
}

#HealthCheck-title-container-time {
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 12px;
}

#HealthCheck-button-container {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
}

#HealthCheck-form {
    display: flex;
}

.HealthCheck-form-column {
    width: calc(50% - 60px);
    margin: 30px;
}

.HealthCheck-form-row {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 10px;
    width: 100%;
}

.HealthCheck-form-input {
    margin: 10px 0px;
    width: 100%;
}

.HealthCheck-form-warning-div {
    display: flex;
    justify-content: flex-end;
    width: 99%;
    font-size: 13px;
    position: relative;
}

.HealthCheck-form-warning-label {}

#HealthCheck-table-tabs-container {
    margin-top: 10px;
}

.HealthCheck-bulk-fix-div-row-container {
    display: flex;
    flex-direction: column;
}

.HealthCheck-bulk-fix-div-container-title {
    font-size: 20px;
    font-weight: bold;
    text-decoration: underline;
}

.HealthCheck-bulk-fix-div-container {
    display: flex;
    flex-direction: column;
}

.HealthCheck-bulk-fix-div-container-inner {
    overflow-y: auto;
    height: calc(100vh - 250px);
    padding: 20px;
    border-radius: 5px;
}

.HealthCheck-bulk-fix-div-row-container-Charts {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.HealthCheck-bulk-fix-div-row-container-Charts-descriptions-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
}

.HealthCheck-bulk-fix-div-row-container-table {
    width: 100%;
}

.HealthCheck-bulk-fix-div-row-container-Charts-Action {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.HealthCheck-bulk-fix-div-row-container-Charts-Omission {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.HealthCheck-bulk-fix-div-row-container-Charts-toggle-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

@keyframes color-fade-in {
    0% {
        color: #06ac38;
    }

    100% {
        color: black;
    }
}

@keyframes color-fade-out {
    0% {
        color: black;
    }

    100% {
        color: #06ac38;
    }
}

.HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-in {
    animation: 1s ease-in 0s 1 color-fade-in;
    color: black;
    font-weight: bold;
}

.HealthCheck-bulk-fix-div-row-container-Charts-toggle-div-text-out {
    animation: 1s ease-in 0s 1 color-fade-out;
    color: #06ac38;
    font-weight: bold;
}

.HealthCheck-bulk-fix-div-container-title_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HealthCheck-bulk-fix-div-modal {
    display: flex;
    flex-direction: column;
    margin: 20px;
    align-items: flex-start;
}

.HealthCheck-bulk-fix-div-textarea-modal {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
    height: 85px;
}

#HealthCheck-bulk-fix-div-top {
    height: calc(100% - 70px);
    overflow-y: auto;
    border-bottom: 1px solid green;
}

#HealthCheck-bulk-fix-div-bottom {
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
}


#HealthCheck-title-container-buttons-div #Dropdown {
    width: auto;
}

@media only screen and (max-width: 1300px) {
    #HealthCheck-form {
        flex-direction: column;
    }

    .HealthCheck-form-column {
        width: calc(100% - 60px);
    }

    .HealthCheck-form-warning-label {
        max-width: 60%;
    }
}

#HC-Incidents-container {
    height: calc(100vh - 250px);
}

#HC-Incidents-container-left {
    width: 20vw;
    height: 100%;
    border: 3px solid #06ac38;
    border-radius: 10px;
}

#HC-Incidents-container-right {
    width: calc(80vw - 100px);
    margin-left: 20px;
}

.HC-Incidents-container-right-menu {
    padding: 10px 0px;
}

.HC-Incidents-container-right-menu:hover {
    background-color: #e6e3e3;
    color: white;
}

.HC-Incidents-container-right-menu-titles {
    padding: 10px 0px;
    background-color: #06ac38;
    color: white;
}

.HC-Incidents-container-right-menu-titles-inner {
    height: calc(100% - 44px);
    overflow-y: auto;
}

.HC-Incidents-container-right-menu-subtitles {
    padding: 10px 0px;
    background-color: #80b890;
    color: white;
}

.HC-Incidents-container-right-menu-subtitles-chevron {
    padding: 10px 0px;
    background-color: #ffe664;
    color: white;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.HC-Incidents-container-right-menu-subtitles-chevron-text {
    width: 100%;
    justify-content: center;
    display: flex;
}

.HC-Incidents-container-right-menu-chevron {
    width: 45px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.HC-Incidents-container-right-menu-active {
    padding: 10px 0px;
    background-color: #e6e3e3;
    color: white;
}

#HC-Incidents-container-right-top10-table {
    margin-top: 20px;
    height: 54%;
}

#HC-Incidents-container-right-top10-lineBars {
    width: 100%;
    height: 35%;
}

#HC-Incidents-container-right-top10-lineBars canvas {
    height: 100% !important;
}

.HC-Incidents-container-right-metrics-pie {
    height: 240px;
    width: 220px;
}

#HC-Incidents-container-right-metrics-pie-container {
    display: flex;
    justify-content: space-around;
}

#HealthCheck-startmodal-div {
    margin: 40px;
}

#HealthCheck-startmodal-div-buttons {
    margin-top: 20px;
}