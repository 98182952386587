@font-face {
    font-family: 'Plain-Bold';
    src: local('Plain-Bold'), url('./PlainBold/PlainBold-Regular.eot?#iefix') format('embedded-opentype'),
        url('./PlainBold/PlainBold-Regular.woff') format('woff'),
        url('./PlainBold/PlainBold-Regular.woff2') format('woff2'),
        url('./PlainBold/Plain-Bold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: 'Helvetica-Bold';
    src: local('Helvetica-Bold'), url('./HelveticaNeuBold/HelveticaNeuBold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Plain-Bold-Italic';
    src: local('Plain-Bold-Italic'), url('./PlainBoldItalic/PlainBold-Italic.eot?#iefix') format('embedded-opentype'),
        url('./PlainBoldItalic/PlainBold-Italic.woff') format('woff'),
        url('./PlainBoldItalic/PlainBold-Italic.woff2') format('woff2'),
        url('./PlainBoldItalic/Plain-BoldIta.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Plain-Light';
    src: local('Plain-Light'), url('./PlainLight/PlainLight-Regular.eot?#iefix') format('embedded-opentype'),
    url('./PlainLight/PlainLight-Regular.woff') format('woff'),
    url('./PlainLight/PlainLight-Regular.woff2') format('woff2'),
    url('./PlainLight/Plain-Light.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'Helvetica-Light';
    src: local('Helvetica-Light'), url('./HelveticaNeuLight/HelveticaNeueLight.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Plain-Light-Italic';
    src: local('Plain-Light-Italic'), url('./PlainLightItalic/PlainLight-Italic.eot?#iefix') format('embedded-opentype'),
        url('./PlainLightItalic/PlainLight-Italic.woff') format('woff'),
        url('./PlainLightItalic/PlainLight-Italic.woff2') format('woff2'),
        url('./PlainLightItalic/Plain-LightIta.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Plain-Medium';
    src: local('Plain-Medium'), url('./PlainMedium/PlainMedium-Regular.eot?#iefix') format('embedded-opentype'),
        url('./PlainMedium/PlainMedium-Regular.woff') format('woff'),
        url('./PlainMedium/PlainMedium-Regular.woff2') format('woff2'),
        url('./PlainMedium/Plain-Medium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'Helvetica-Medium';
    src: local('Helvetica-Medium'), url('./HelveticaNeuMedium//HelveticaNeueMedium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Plain-Medium-Italic';
    src: local('Plain-Medium-Italic'), url('./PlainMediumItalic/PlainMedium-Italic.eot?#iefix') format('embedded-opentype'),
        url('./PlainMediumItalic/PlainMedium-Italic.woff') format('woff'),
        url('./PlainMediumItalic/PlainMedium-Italic.woff2') format('woff2'),
        url('./PlainMediumItalic/Plain-MediumIta.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Plain-Regular';
    src: local('Plain-Regular'), url('./PlainRegular/PlainRegular-Regular.eot?#iefix') format('embedded-opentype'),
        url('./PlainRegular/PlainRegular-Regular.woff') format('woff'),
        url('./PlainRegular/PlainRegular-Regular.woff2') format('woff2'),
        url('./PlainRegular/Plain-Regular.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'Helvetica-Regular';
    src: local('Helvetica-Regular'), url('./HelveticaNeuRegular/HelveticaNeue.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Plain-Regular-Italic';
    src: local('Plain-Regular-Italic'), url('./PlainRegularItalic/PlainRegular-Italic.eot?#iefix') format('embedded-opentype'),
        url('./PlainRegularItalic/PlainRegular-Italic.woff') format('woff'),
        url('./PlainRegularItalic/PlainRegular-Italic.woff2') format('woff2'),
        url('./PlainRegularItalic/Plain-RegularIta.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Plain-Thin';
    src: local('Plain-Thin'), url('./PlainThin/PlainThin-Regular.eot?#iefix') format('embedded-opentype'),
        url('./PlainThin/PlainThin-Regular.woff') format('woff'),
        url('./PlainThin/PlainThin-Regular.woff2') format('woff2'),
        url('./PlainThin/Plain-Thin.otf') format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: 'Helvetica-Thin';
    src: local('Helvetica-Thin'), url('./HelveticaNeuThin/HelveticaNeueThin.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Plain-Thin-Italic';
    src: local('Plain-Thin-Italic'), url('./PlainThinItalic/PlainThin-Italic.eot?#iefix') format('embedded-opentype'),
        url('./PlainThinItalic/PlainThin-Italic.woff') format('woff'),
        url('./PlainThinItalic/PlainThin-Italic.woff2') format('woff2'),
        url('./PlainThinItalic/Plain-ThinIta.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}