.tourCardTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.tourCardTitle {
    font-weight: 400;
    font-size: 32px;
}

.tourCardButton {
    background-color: #048A24;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.tourCardCloseButton {
    background-color: transparent;
    height: 19px;
    width: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border: none;
    outline: none;
    cursor: pointer;
    color: black;
}

.tourCardDescription {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #424242;
    text-align: left;
    margin-bottom: 10px;
}

.progressBar {
    background-color: #E0E0E0;
    border-radius: 32px;
    height: 16px;
    width: 100%;
}

.progress {
    background-color: #048A24;
    border-radius: 32px;
    height: 16px;
    transition: 'width 0.3s ease-in-out'
}

.progressText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #424242;
    display: flex;
    text-wrap: nowrap;
}

.progressBarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}

.tourCardFooter {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.tourCardButtonPrimary {
    background-color: #048A24;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.tourCardButtonSecondary {
    background-color: #fff;
    border-radius: 8px;
    color: #424242;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    border: 1px solid #424242;
    outline: none;
    cursor: pointer;
}