#Dropdown {
  width: auto;
  position: relative;
}

#Dropdown:has(input:disabled) {
  background-color: white;
  border-radius: 8px;
}

#Dropdown-options {
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #8A95A8;
  border-radius: 8px;
	margin: 0;
	padding: 0;
	z-index: 10;
	position: absolute;
	left: 0;
	top:100%;
}

.Dropdown-option,.Dropdown-option-active {
  padding: 8px 8px;
  font-size: 14px;
}

.Dropdown-option:hover {
  background-color: #e1e1e1;
}

.Dropdown-option-active {
  background-color: #e1e1e1;
}

.Dropdown-option-active:hover {
  background-color: #e1e1e1;
}

#Dropdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: row;
  border-radius: 8px;
  border: 1px #8A95A8 solid;
  padding: 5px 5px;
}

#Dropdown-container input{
  margin: 0px;
  outline: none;
  font-size: 14px;
  position: relative;
  width: auto;
  padding: 13px 12px;
  border: none;
  background-color: transparent;
}

#Dropdown-container > svg{
  right: 5px;
  pointer-events: none;
  border-radius: 8px;
  padding: 13px 12px;
}


