.container__graphics {
  display: flex;
  width: 100%;
  gap: 10px;
  padding-top: 10px;
}
.graphics {
  width: 100%;
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0 .75rem 6rem rgba(56, 65, 74, .03);
  flex-direction: column;
  margin-top: 10px;
  justify-content: center;
  padding: 16px;
}
.container__cardTypeAlert {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 8px;
  overflow-y: scroll;
  margin-top: 20px;
  max-height: 600px;
}
.card__typeAlert {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
  gap: 24px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
}
.count__totalAlertsSummary {
  font-size: 24px;
  font-weight: 700;
  line-height: 25.5px;
  letter-spacing: 0.5px;
  color: rgba(52, 52, 52, 1);
}
.container__countTotalAlertsSummary {
  text-align: center;
}
.name__alerts {
  font-size: 12px;
  font-weight: 400;
  line-height: 25.5px;
  letter-spacing: 0.5px;
}
.container__cardBetterWorse {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  padding: 20px;
}
.title__cardTypeBetterWorse {
  margin-bottom: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  color: #848484;
  font-size: 10px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
}

.title__nameTeam {
  margin-bottom: 12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  color: #181818;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
}

.container__betterWorse {
  display: flex;
  margin-top: 10px;
  column-gap: 10px;
}

.button__infoCircleTypeAlert {
  margin: 0px auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 18px;
  cursor: pointer;
}

.container__severityDashboard {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 8px;
}

.punctuation__footer {
  margin-top: auto;
  display: flex;
  background-color: #E5F2E9;
  border-radius: 5px;
  padding: 4px 8px 4px 8px;
  align-items: center;
}

.infoCircleTypeAlert {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;

}

.container__scoreCircle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 10px;
}

.score__alerts {
  margin-left: 10px;
  font-weight: 600;
  font-size: 15px;
}

.view__detailAlerts {
  text-align: right;
}

.container__alertDetail {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 41px;
}

.container__allAlerts {
  padding: 15px;
}
.container__sectionDashboard {
  width: 76% !important;
}

.container__severityModal {
  width: 100%;
  margin-top: auto;
  padding-top: 10px;
}

.title__descriptionInfo {
  font-weight: 600;
  margin-top: 15px;
}

.separator {
  border-bottom: 1px solid #ddd;
  height: 1px;
  margin-top: 21px;
}

.container__paginationDashboard {
  margin-top: 15px;
}

.container__severityScore {
  display: flex;
  justify-content: space-between;
}

.try__againTemAlerts {
  text-align: center;
}

.icon__fax {
  cursor: pointer;
}

.container__cartAndTitle {
  display: flex;
  justify-content: space-between;
}

.top-three-most-critical-alerts-card {
  border: 1px solid #ddd;
  border-radius: 16px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: auto;
  max-width: 258px;
  display: flex;
  flex-direction: column;
}

.top-three-most-critical-alerts {
  font-size: 14px;
}

.top-three-most-critical-alerts-container {
  margin-top: 20px;
}
.card__topThreeMost {
  display: flex;
  gap:16px;
  flex-wrap: wrap;
  margin-top: 20px;
}
.container__cardTopThreeMost {
  margin-top: 10px;
  margin-bottom: 30px;
}
.alertsSummary__container {
  margin-top: 60px;
  width: 100%;
}

.top-three-most-critical-alerts-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px 0px;
  box-sizing: border-box;
}

.top-three-most-critical-alerts-label {
  font-size: 14px;
  color: #333;
  padding: 0px 5px 0px 0px;
  cursor: pointer;
}
.top-three-most-critical-alerts-label:hover {
  transform: translateY(-2px);
}

.top-three-most-critical-alerts-score {
  font-size: 15px;
  font-weight: bold;
  display: flex;
  background-color: #E5F2E9;
  border-radius: 5px;
  padding: 4px 8px;
  align-items: center;
  align-self: flex-start;
}
.container__buttonTry {
  display: flex;
  justify-content: center;
}
.centered-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
}
.urgency__dashboard {
  background: #c12f2a;
  padding: 0.3em;
  border-radius: 8px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  gap: 8px;
}
.container__barMonthlyHealthCheck {
  width: 100%;
  background: rgba(250, 250, 250, 1);
  padding: 24px;
  border-radius: 16px;
}
.line__nameSelected {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  color: rgba(52, 52, 52, 1);
  margin-bottom: 25px;
}
.container__alertsTypeSummary {
  width: 100%;
}