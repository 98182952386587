
#Deck-generator-container {
  margin: 0px 10px 10px 10px;
  font-family: 'Plain-Regular';
  padding: 0  1.5em;
}

#Deck-generator-title-container {
  display: flex;
  padding-bottom: 10px;
  width: 100%;
  justify-content: space-between;
}
#Deck-generator-title {
  font-size: 30px;
  margin: 0;
  margin-left: 10px;
  margin-top: 5px;
  color: #117170;
}


#DeckGenerator-FAQ {
  display: flex;
  justify-content: end;
  }
  
  #DeckGenerator-FAQIcon {
  cursor: pointer;
  color: #015A23;
  font-size: 16px;
  margin-left: 10px;
  }

@media (min-width: 700px) {
  .wrapper {
    overflow: hidden;
  }
  .wrapper > * {
    float: left;
    padding: 2em 2em;
  }
}

@media (min-width: 700px) {
  .contacts {
    width: 30%;
    min-height: 620px;
  }
}

@media (min-width: 700px) {
  form #deck-form {
   width: 100%;
  }
}

form #deck-form {
  float: left;
  width: 500px;
  margin-bottom: 10px;
}
form #deck-form:not(:nth-child(2n)) {
  margin-right: 1em;
}

form #deck-form:last-child {
  clear: both;
  width: 100%;
}

.info-message {
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 20px;
  color: #03AC37;
  font-weight: bold;
}

.beta-tag{
  display: flex;
  margin-left: 20px;
  background-color: #03AC37;
  padding: 3px;
  border-radius: 4px;
  color: white;
}


/**************************************/


form {
  display: flex;
  flex-direction: column;
}

.wrapper {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.wrapper > * {
  padding: 2em;
}

ul {
  list-style: none;
  padding: 0;
}

.deck-generator-title {
  font-weight: 500;
  margin-left: 10px;
  font-size: 16px;
}
.contain-skeleton{
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px 30px;
}

.contacts {
  background: #4b5195;
  color: #fff;
}
.form {
  text-align: start;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 5px;
  padding: 25px 25px;
  border: 1px solid #CCC;
}
form label {
  display: block;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #333; /* Adjust to the exact color from the layout */
}

.deck-generator {
  width: 100%;
  border: 1px solid #CCC;
  border-radius: 5px;
  color: #000;
  text-align: start;
}

input:disabled {
  background-color: #eee;
  cursor: not-allowed;
  color: rgb(17 24 39 / 1);
  opacity: 0.5;
}

.deck-generator {
  padding: 10px;
}

select, .deck-generator {
  padding-right:10px;
}

 .deck-generator{
  background-color: #fff;

}

 .deck-generator{
  margin-left: 10px;

}

.deck-generator-multi label {
  margin-bottom: 10px;
}

.deck-generator-multi .InputContainer {
  margin-left: 10px;
}

.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-left: 10px;
}

.deck-generator-button {
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  padding: 15px 25px;
  border: none;
  background-color: #03AC37;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  margin-left: 10px;
  display: block;
  width: 100%; 
  text-align: center; 
  width: 200px;
}

.deck-generator-button:hover {
  background-color: #015A23;
}

.deck-generator-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.deck-tour-button {
  padding: 10px 20px;
border-radius: 5px;
outline: none;
margin-right: 15px;
border: 1px solid #afafaf;
cursor: pointer;
background-color: rgb(6, 172, 56);
color: white;

&:hover {
  background-color: #ffffff;
    color: rgb(6, 172, 56);
  }
}

.EmailInputContainer {
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-grow: 1;
  background-color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}


.InputInput {
  border: 0px;
  outline: none;
  flex-grow: 1;
  min-width: 100px;
  text-align: start;
  margin: 0px;
}

.Flex {
  display: flex;
  flex: auto 0 0;
}

@keyframes shimmerInput {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.skeleton-input {
  height: 40px; /* Height of your input field */
  width: 100%; /* Width of your input container */
  background: #eee; /* Light grey background */
  background-image: linear-gradient(to right, #f0f0f0 8%, #ddd 18%, #f0f0f0 33%);
  background-size: 800px 100px;
  animation: shimmerInput 1.5s linear infinite forwards;
  border-radius: 4px; /* Mimic the border-radius of typical inputs */
  margin: 5px 0; /* Optional, for spacing */
  margin-left: 10px;
  border: 1px solid #CCC;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.skeleton-text {
  display: inline-block;
  height: 16px; /* Height of your text line */
  width: 100%; /* Width of your text container */
  background: #eee; /* Light grey background */
  background-image: linear-gradient(to right, #eee 0%, #f5f5f5 20%, #eee 40%, #eee 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: shimmer 1.5s linear infinite;
  border-radius: 4px;
}