.container__tabs {
  display: flex;
  justify-content: center;
}
.tab__active {
  padding: 6px 16px 6px 12px;
  border-radius: 8px 8px 0px 0px;
  cursor: pointer;
  border-left: solid 1px rgba(233, 233, 233, 1); 
  border-top: solid 1px rgba(233, 233, 233, 1);
  border-right: none;  
  border-bottom: none; 
  background: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.tab__inactive  {
  padding: 6px 16px 6px 12px;
  gap: 8px;
  border-radius: 8px 8px 0px 0px;
  border: 0px 1px 0px 1px;
  cursor: pointer;
  background: rgba(244, 244, 244, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color:  rgba(100, 100, 100, 1);
}