#SuccessOnDemand-container {
    margin: 20px 25px;
    height: calc(100vh - 138px);
    width: 90%;
}

#SuccessOnDemand-loader {
  position: absolute;
  z-index: 100;
  width: 100%;
  background-color: #ffffff73;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#SuccessOnDemand-title {
  font-size: 30px;
  margin: 0;
  margin-left: 15px;
  color: #117170;
  margin-top: 5px;
}

#SuccessOnDemand-title-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

#SuccessOnDemand-container-time {
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  font-size: 12px;
}

#SuccessOnDemand-form {
  display: flex;
}

.SuccessOnDemand-form-column {
  width: calc(50% - 60px);
  margin: 30px;
}

.SuccessOnDemand-form-row {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 10px;
}

.SuccessOnDemand-form-input {
  margin: 10px 0px;
}

.SuccessOnDemand-form-warning-div {
  display: flex;
  justify-content: flex-end;
  width: 99%;
  font-size: 13px;
  position: relative;
}

.SuccessOnDemand-form-warning-label {
  position: absolute;
}

#SuccessOnDemand-filter {
  display: flex;
  justify-content: space-between;
}

.SuccessOnDemand-filter-input {
  width: 200px;
  margin: 17px 0px 10px 10px;
  outline-color: #005a24;
  border-radius: 3px;
  padding: 5px;
  text-align: initial;
  cursor: text;
}

.SuccessOnDemand-filter-div {
  display: flex;
  width: 350px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 38px;
}

.SuccessOnDemand-tabs-div {
  margin-top: 9px;
  height: calc(100% - 167px);
}

#SuccessOnDemand-modal-container {
  margin: 40px;
  height: calc(100% - 140px);
}

#SuccessOnDemand-button-container {
  width: 100%;
  display: flex;
  margin-top: 15px;
}
.sod-container-right {
  display: flex;
}

.soc-empty-state,
.soc-empty-query {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.soc-empty-query h3 {
  border-radius: 15px;
  color: #4b4f4f;
  padding: 5em;
  background-color: #e7ddcf;
}

.soc-empty-state h3 {
  border-radius: 15px;
  color: #4b4f4f;
  padding: 5em;
  background-color: #e7ddcf;
}

#SOD-Incidents-container-right-SLA-lineBars {
  width: 100%;
  height: 100%;
}
#SOD-Incidents-container-right-SLA-StacklineBars {
  width: 100%;
  height: 100%;
  height: 550px;
  max-height: 700px;
}

#SOD-Incidents-container-right-SLA-table {
  height: calc(100% - 63px);
}

.SOD-form-row {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 10px;
  width: 100%;
  padding: 40px;
}
.SOD-form-row-inline {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
}

.SOD-form-input {
  margin: 10px 0px;
  width: 100%;
  border: 1px solid #e0e1e2;
}

#SOD-form-row-buttons {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: end;
}

.SuccessOnDemand-filter-container:not(:first-child) {
  display: flex;
  align-items: center;
}

.SuccessOnDemand-filter-container div:not(:first-child) {
  margin-left: 10px;
}

.SuccessOnDemand-filter-container button:not(:first-child) {
  margin-left: 10px;
}

#SD-container-right-table {
  margin-top: 40px;
  height: 54%;
}

.SOD-first-step {
  display: flex;
}

.input-container,
.input-container-report {
  display: flex;
  flex-direction: column;
}

.input-container label,
.input-container-report label {
  font-size: 10px;
  text-align: left;
  font-weight: 600;
}

.input-container .input-search {
  outline: none;
  flex-grow: 1;
  min-width: 100px;
  text-align: start;
  margin: 0px;
  display: flex;
  border: 1px solid hsl(0, 0%, 80%);
  align-items: end;
  width: auto;
  overflow-x: auto;
  flex-grow: 1;
  padding-left: 8px;
  background-color: white;
  border-radius: 5px;
}

.input-container-report input {
  min-width: 350px;
}

.search-container {
  margin-right: 10px;
}

.sod-button-export {
  color: #fff;
  padding: 0.7em 1.5em;
  border-radius: 10px;
  text-decoration: none;
  position: relative;
  font-weight: 500;
  border: 1px solid;
  background-color: #016080;
}

.sod-button-export:hover {
  color: #016080;
  background-color: #a5c6df;
}

.sod-filter-panel-statusApp {
  margin: 10px 0px;
  display: flex;
  width: min-content;
  align-items: end;
  width: 100%;
  margin-bottom: 10px;
  justify-content: end;
}

#SuccessOnDemand-FAQ {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 13px;
  margin-right: 20px;
}

#SuccessOnDemand-FAQIcon {
  cursor: pointer;
  color: #117170;
  font-size: 16px;
  margin-left: 10px;
}
