.profile-container {
  margin: 1.5em;
  padding: 3em;
  height: calc(100% - 8em);
  border-radius: 5px;
  width: 100%;
}

.profile-maininfo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.profile-maininfo h3{
  font-size: 30px;
  margin-bottom: 2px;
}

.profile-maininfo .title {
  color: #00607f;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin:3px auto
}

.profile-maininfo img {
  width: 150px;
  border-radius: 50%;
}


.profile-general-info a {
  margin-top: 4em;
  display: inline-block;
  color: black;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  font-size: 17px;
  border-radius: 5px;
}

/* Change the color of links on mouse-over */
.profile-general-info a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.profile-general-info a.active {
  background-color: #06ac38;
  color: white;
}

.profile-general-info_container {
  padding: 2em 3em;
  display: flex;
  flex-direction: column;
}

.profile-general-info_container .subtitle{
  display: inline;
}

.profile-general-info_container .inline{
  display: inline-block;
}

