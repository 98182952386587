.card {
    border-radius: 16px;
    border-left: 3px solid rgba(17, 113, 112, 1);
    background-color: #fff;
    color: #3e3e3e;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: 16px;
    width: calc(50% - 10px);
  }
  
  .cardTitle {
    font-family: Plain, sans-serif;
    font-weight: 600;
    line-height: 150%;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .cardDescription {
    font-family: Plain, sans-serif;
    font-weight: 200;
    line-height: 24px;
    margin-bottom: 0px;
  }
  
  @media (max-width: 991px) {
    .card {
      width: 100%;
      margin-top: 16px;
    }
  }