.page-title-container {
  position: relative;
  flex: 1 1 0;
  display: flex;
  align-items: center;
}

.page-title {
  font-size: 20px;
}

.page-main-content {
  position: relative;
  flex: 1 1 0;
  display: flex;
  align-items: center;
}

.select-input {
  background-color:  #FFF;
  color:black;
}

.user-detail input, .user-detail select{
  border-radius: 4px;
}

.user-form {

}

.form-section {
  text-align: left;
  margin: 20px 0;
}

.form-layout {
  display: flex;
  flex-wrap: wrap;
}

.form-layout select{
  min-width: 235px;
  border: 2px solid black;
}

.switch-container {
display: flex;
margin-top: 18px;
}

.switch-container .user-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-container .user-slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .user-slider {
  background-color: #06ac38;
}

input:focus + .user-slider {
  box-shadow: 0 0 1px #06ac38;
}

input:checked + .user-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.user-slider.round {
  border-radius: 34px;
}

.user-slider.round:before {
  border-radius: 50%;
}

.switch-label {
  display: flex;
  align-items: baseline;
  justify-content: end;
  width: 100%;
}

.role-container label{
  margin: 0 20px;
}