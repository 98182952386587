.container__copyright {
  width: 100%;
  height: fit-content;
  background-color: white;
  padding: 20px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  /* margin-bottom: 10px; */
}