.messagesList {
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
  flex-direction: column;
  display: flex;
  align-items: center;
}


.icon {
  margin-right: 10px;
}

.messageCard {
  width: 100%;
  margin: 5px 20px;
  max-width: 800px;
  padding: 10px;
  border-radius: 6px;
  z-index: 100;
  display: flex;
  height: 50px;
  align-items: center;
  -webkit-animation: fadeinout 2s linear forwards;
  animation: fadeinout 5s linear forwards;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
}

@-webkit-keyframes fadeinout {
  0%, 100% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%, 100% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }
  
  80% {
    opacity: 1;
  }
}