.container__graphics {
  display: flex;
  width: 100%;
  gap: 10px;
  padding-top: 10px;
}

.container__cardBetterWorse {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  padding: 20px;
}

.link__typeBetterWorse {
  font-size: 14px;
  font-weight: 375;
  line-height: 21px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 8px;
  margin-left: 12px;
}

.border__bottom {
  border-bottom: 1px solid #ddd;
}

.teams__container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 17px;
  row-gap: 17px;
  margin-top: 30px;

}

.team-column {
  padding: 24px;
  border-radius: 16px;
  border-left: 3px solid  rgba(135, 135, 135, 1);
  background:  rgba(250, 250, 250, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
  height: auto;
  width: 24%;
  display: flex;
  flex-direction: column
}
.title__nameTeamDashboard {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color:  rgba(52, 52, 52, 1);
  margin-bottom: 16px;
}

.title__cardTypeBetterWorse {
  margin-bottom: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  color: #848484;
  font-size: 10px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
}

.title__nameTeam {
  margin-bottom: 12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  color: #181818;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
}

.container__betterWorse {
  display: flex;
  margin-top: 10px;
  column-gap: 10px;
}
.container__selectTeam {
  display: flex;
  margin-top: 10px;
  column-gap: 10px;
  margin-bottom: 20px;
}
.punctuation__footerDashboardTeam {
  margin-top: auto;
  display: flex;
  background-color: #E5F2E9;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 375;
  line-height: 21px;
  text-align: left;
}
.view__moreTeam {
  font-size: 14px;
  font-weight: 375;
  line-height: 21px;
  text-align: left;
  color: rgba(12, 64, 128, 1);
  cursor: pointer;
}

.infoCircleTypeAlert {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;

}

.container__scoreCircleDashboardTeam {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 10px;
}
.numeric__impactTitle {
  display: flex;
  align-items: center;
}
.numeric__impact {
  padding: 4px;
  border-radius: 4px;
  background:  rgba(205, 0, 0, 1);
  color: rgba(250, 250, 250, 1);
  font-size: 14px;
  font-weight: 375;
  line-height: 21px;
  text-align: left;
}

.score__alertsTeams {
  margin-left: 10px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
}

.view__detailAlerts {
  text-align: right;
}

.text__description {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color:  rgba(52, 52, 52, 1);
}

.container__alertDetail {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 41px;
}

.container__allAlerts {
  padding: 15px;
}

.title__alertModal {
  font-size: 14px;
  overflow-wrap: break-word;
  color: #4e4b4b;
  cursor: pointer;
  margin-bottom: 20px;
}

.container__sectionDashboard {
  width: 76% !important;
}

.container__severityModal {
  width: 100%;
  margin-top: auto;
  padding-top: 10px;
}

.title__descriptionInfo {
  font-weight: 600;
  margin-top: 15px;
}

.separator {
  border-bottom: 1px solid #ddd;
  height: 1px;
  margin-top: 21px;
}

.container__paginationDashboard {
  margin-top: 15px;
}

.container__severityScore {
  display: flex;
  justify-content: space-between;
}

.select__teams {
  padding: 16px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(224, 224, 224, 1);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="none" stroke="%23333" stroke-width="1" d="M0 0l5 5 5-5"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 5px;
  padding-right: 100px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color:  rgba(0, 0, 0, 1);
}
.tittle__selectTeam {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 8px;
}

.text__teamSelectionMode {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 375;
  line-height: 21px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
}

.try__againTemAlerts {
  text-align: center;
}

.icon__fax {
  cursor: pointer;
}
.container__cartAndTitle {
  display: flex;
  justify-content: space-between;
}
.icon__chart {
  text-align: end;
  color: rgb(6, 172, 56);
  cursor: pointer;
}

.chart-container {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}
.tittle__byEntity {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 1);
}
.container__teamByMonth {
  margin-bottom: 40px;
}
.container__alertByTeamMonth {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  row-gap: 15px;
  margin-top: 20px;
}
.card__alertByTeamMonth {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
}
.card__alertByTeamMonth>div {
  font-size: 14px;
  color: #333;
}

.card__alertByTeamMonth>div:last-child {
  color:  rgba(52, 52, 52, 1);
  font-size: 25px;
  font-weight: 700;
  line-height: 25.5px;
  letter-spacing: 0.5px;
  text-align: left;

}
.count__alert{
  background-color: #E5F2E9;
  padding: 10px;
  border-radius: 5px;
}
.field__commercialName {
  cursor: pointer;
}
.select__teamDashboardTeam {
  width: 100%;
  display: flex;
}
.select__month {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 7px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="none" stroke="%23333" stroke-width="1" d="M0 0l5 5 5-5"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 5px;
  padding-right: 100px;
  margin-left: 10px;
  height: 43px;
}
.container__infoAlertsByMonth {
  padding: 32px ;
  border-radius: 16px ;
  border: 1px solid  rgba(233, 233, 233, 1)
}
.container__selectMonth {
  margin-top: 33px;
}
.container__teamsSelected {
  display: flex;
  /* column-gap: 2px; */
  flex-wrap: wrap;
  justify-content: center;
}
.select__teamActivated {
  border-radius: 5px 5px 0px 0px;
  background-color: rgb(6, 172, 56);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 9px;
  margin-left: 4px;
  cursor: pointer;
  text-align: center;
}
.select__teamDisabled {
  border-radius: 5px 5px 0px 0px;
  background-color: #F0F0F0;
  color: rgb(6, 172, 56);
  font-size: 14px;
  font-weight: 600;
  padding: 9px;
  margin-left: 2px;
  cursor: pointer;
  text-align: center;
}
.table__comparison {
  margin-bottom: 50px;
}
/* .title__sessionDashboardTeam {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  color:rgba(0, 0, 0, 1);
} */
.month__select {
  margin-top: 10px;
  margin-left: 10px;
}
.container__monthlyHealth {
  margin-top: 40px;
  width: 100%;
}
.th__dashboardTeam {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color:  rgba(100, 100, 100, 1);
}
.container__barMonth {
  width: 100%;
  margin-top: 50px;
  background:  rgba(250, 250, 250, 1);
  padding: 24px;
  border-radius: 16px;
}