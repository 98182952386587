.faq-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: gray;
  margin: 0 auto;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height:100vh;
  width: 100%;
}
 
