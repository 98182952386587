:root {
    --primary-color: #048a24;
    --secondary-color: #005a24;
    --background-color: #f4f4f4;
    --black-color: #000;
    --white-color: #fff;
    /* --gray-color: #c9c9c9; */
    --gray-color: #dedede;
    --blue-color: #082b56;
    --light-blue-color: #117170;
    /* NEW COLORS */
    --muted-color: #78858f;
    --yellow-color: #ffe664;
    --red-color: #cd0000;
    --purple: #aa00d5;
    --green-odin-s-beard-color: #e5f2e9;
    --green-electric-jade-color: #adf07e;
    --green-300-color: #88fca3;
    --green-400-color: #60fa85;
    --green-500-color: #38f966;
    --green-600-color: #11f848;
    --green-700-color: #06d939;
    --green-800-color: #05b22e;
    --blue-300-color: #0c4080;
    --blue-200-color: #146bd5;
    --blue-100-color: #d1d4de;
    --gray-100-color: #f4f4f4;
    /* --gray-200-color: #e9e9e9; */
    --gray-200-color: #f2f2f2;
    --gray-300-color: #bbbbbb;
    --gray-400-color: #878787;
    --gray-500-color: #646464;
    --gray-600-color: #535353;
    --gray-700-color: #343434;
    --success-100-color: #96ffc0;
    --success-200-color: #2dff81;
    --success-300-color: #00c34e;
    --warning-100-color: #ffe2b1;
    --warning-200-color: #eeb900;
    --warning-300-color: #e87000;
    --warning-400-color: #9a4a00;
    --error-050-color: #fee9e9;
    --error-100-color: #e4626f;
    --error-200-color: #c03744;
    --error-300-color: #8c1823;
  }
  