.skeleton-input-HCDV-title {
  animation: skeleton-loading 1s linear infinite alternate;
  border: 1px solid #0000004d;
  border-radius: 5px;
  width: 100%;
  padding: 17px 24px;
  margin-top: 5px;
  border: 1px hsl(200, 20%, 80%) solid;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 0px;
}

.skeleton-text-HCDV-title {
  animation: skeleton-loading-text 1s linear infinite alternate;
}

.skeleton-input-HCDV {
  animation: skeleton-loading 1s linear infinite alternate;
  border: 1px solid #0000004d;
  border-radius: 5px;
  width: calc(100% - 20px);
  padding: 17px 24px;
  margin: 10px;
  border: 1px hsl(200, 20%, 80%) solid;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 0px;
}

.skeleton-text-HCDV {
  animation: skeleton-loading-text 1s linear infinite alternate;
}

.skeleton-IMG-HCDV {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@keyframes skeleton-loading-text {
  0% {
    color: hsl(200, 20%, 80%);
  }
  100% {
    color: hsl(200, 20%, 95%);
  }
}
