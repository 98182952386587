
 .Table-Container {
  flex: 1 1 0;
}
.Table-Container .Table-Header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
}
.Table-Container .Table-Header:not(:first-child) {
  padding-left: 16px;
}
.Table-Container .Table-Header .Table-Title-Container {
  flex: 1 1 0;
}
.Table-Container .Table-Header .Table-Header-Actions {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: none;
}
.Table-Container .Table-Header .Table-Header-Actions label{
 font-size: 13px;
 font-weight: 500;
}
.Table-Container .Table-Header .Table-Header-Actions select{
 font-size: 13px;
 font-weight: 500;
}
.Table-Container .Table-Header .Table-Header-Actions > *:not(:last-child) {
  margin-right: 12px;
}
.Table-Container .Table-Sort-Filter-Tags-List-Container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.Table-Container .Table {
  width: 100%;
  border-collapse: collapse;
}
.Table-Container .Table .Table-Head tr th {
  border-bottom: solid 1px #E9E9E9;
  color: #646464;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 16px;
  text-align: left;
}
.Table-Container .Table .Table-Body tr td {
  padding-top: 16px;
  padding-bottom: 16px;
  color: #666A6E;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  padding-right: 8px;
  padding-left: 8px;
}
.Table-Container .Table .Table-Body tr:not(:last-child) td {
  border-bottom: solid 1px #E9E9E9
}
.Table-Container .Table .Table-Body tr:nth-child(odd) td {
  background-color: #FAFAFA;
}
.Table-Container .Table .Table-Body tr:hover td {
  background: #E9E9E9;
}
.Table-Container .Table-Text-Empty {
  text-align: center;
}

.Display-Text.displaySmall {
 font-size: 20px;
 font-weight: 500;
}

.Button.primary {
 position: relative;
 font-size: 14px;
 font-weight: 500;
 color: #fff;
 background-color: #005a24;
 border: solid 1px #000;
 border-radius: 4px;
 cursor: pointer;
 box-sizing: border-box;
 padding: 12px 20px;
 padding-right: 45px!
}

.action-column {
 display: flex;
 justify-content: space-around;
 align-content: center;
}
.delete-icon, .edit-icon{
 width: 20px;
}