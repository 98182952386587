.metric__card {
  padding: 24px ;
  border-radius: 16px;
  width: 446px;
  border-left: 3px solid   rgba(17, 113, 112, 1);
  flex-basis: calc(25% - 16px);
  background: rgba(250, 250, 250, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  cursor: pointer;
}
.metric__cardTitle {
  font-size: 14px;
  color: #333;
}
.metric__cardValue {
  font-size: 24px;
  font-weight: 700;
  line-height: 25.5px;
  letter-spacing: 0.5px;
}
.metric__card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.metric__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 25.5px;
  letter-spacing: 0.5px;
  text-align: center;
}
.metric__containerText {
  text-align: center;
}

