#PDU-container {
  margin: 0px 10px 10px 10px;
  font-family: 'Plain-Regular';
  padding: 0  1.5em;
  width: calc(100vw - 5rem);
  height: calc(100vh - 5rem);
  overflow-y: scroll;
}

#PDU-title-container {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.pdu-button-export {
  color: #fff;
  padding: 0.7em 1.5em;
  border-radius: 10px;
  text-decoration: none;
  border: none;
  font-weight: 500;
  background-color: #016080;
}

#pdu-FAQ {
  display: flex;
  justify-content: end;
  align-items: center;
  align-self: end;
  margin-right: auto;
  margin-left: 20px;
}

#pdu-FAQIcon {
  cursor: pointer;
  color: rgb(255, 230, 100);
  font-size: 16px;
  margin-left: 10px;
}
