
:root {
  --primary-gray: #535353;
  --dark-danger: #C03744;
}

.pdAccordion .accordionContent {
  font-size: 1rem;
  padding-top: 8px;
}
.reconnectContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background: linear-gradient(179deg, #e2f0e7 -39.33%, #fff 31.18%);
  box-shadow: 10px 14px 44px 0 rgba(0, 0, 0, 0.25);
  padding-bottom: 40px;
}

.headerReconnect {
  position: relative;
  height: 80vh;
  width: 100%;
  padding: 40px 40px 80px;
  overflow: hidden;
}

.headerBackground {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.backgroundImage2 {
  width: 100%;
  height: 100%;
  max-height: 460px;
  object-fit: cover;
}
button {
  transition: background-color 0.6s;
  border-radius: 8px;
}

.logoContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 463px;
  padding: 0 60px;
}

.logo {
  width: 19px;
}

.headerContent {
  position: relative;
  z-index: 10;
  display: flex;
  margin-top: 0px;
  width: 100%;
  align-items: center;
}

.headerText {
  flex: 2;
  padding: 0 20px;
}

.textLogo {
  width: 185px;
  max-width: 100%;
}

.greeting {
  color: #adf07e;
  font: 400 88px Plain, sans-serif;
  margin-top: 24px;
}

.subGreeting {
  color: #fff;
  font: 375 24px/150% Plain, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: 24px;
}

.headerImage {
  flex: 1;
  object-fit: contain;
  max-width: 100%;
}

.mainSection {
  padding-top: 32px;
}
.MarginParragraph{
  margin: 16px 0 0 0;
}
.MBNone {
  margin-bottom: 0;
}
.contentWrapper {
  display: flex;
  max-width: 1325px;
  margin: 0 auto;
  gap: 140px;
}

.dashboardImage {
  width: 44%;
  object-fit: contain;
}

.textContent {
  width: 56%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 22px;
  color: #161616;
  line-height: 1.5;
}

.sectionTitle {
  color: #3e3e3e;
  font: 600 40px/52px Plain, -apple-system, Roboto, Helvetica, sans-serif;
}

.sectionDescription {
  font-family: Plain, sans-serif;
  margin-top: 16px;
}

.featureBox {
  display: flex;
  align-items: center;
  background-color: #e5f2e9;
  border-radius: 16px;
  padding: 16px 32px;
  margin-top: 16px;
  gap: 20px;
}

.featureIcon {
  width: 25px;
  margin-right: 20px;
  margin: 0px;
}

.featureText {
  font-family: Plain, sans-serif;
  margin: 0px;
  font-size: 1rem;
}

.titleDescription {
  font: 375 1.2rem / 1.8rem Plain, -apple-system, Roboto, Helvetica, sans-serif!important;
}
.solutionsSection {
  text-align: center;
  position: relative;
  padding: 0px 60px 72px 60px;
}

.solutionsTitle {
  color: #3e3e3e;
  font: 600 40px/48px Plain, -apple-system, Roboto, Helvetica, sans-serif;
  z-index: 2;
}

.solutionsTitle2 {
  font-size: 32px;
}

.solutionsSubtitle {
  color: #848484;
  font: 375 14px/150% Plain, -apple-system, Roboto, Helvetica, sans-serif;
}

.solutionsDescription {
  color: #3e3e3e;
  font: 375 22px/33px Plain, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: 16px;
  max-width: 748px;
  z-index: 2;
  font-weight: 200!important;
}

.solutionsDescriptionContainer {
  display: flex;
  justify-content: center;
}

.solutionsGrid {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 32px;
  position: relative;
  z-index: 2;
}

.startTourButton {
  border: 1px solid #048a24;
  background-color: #fff;
  color: #048a24;
  font: 600 18px/150% Plain, -apple-system, Roboto, Helvetica, sans-serif;
  padding: 16px;
  margin-top: 32px;
  cursor: pointer;
}
.startTourButton:hover {
  background-color: #048a24!important;
  color: white;
}
.healthCheckSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px;
}

.healthCheckContent {
  display: flex;
  width: 100%;
  max-width: 1325px;
  gap: 140px;
  align-items: center;
}

.imageContainer {
  position: relative;
  width: 50%;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logoOverlay {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 108px;
}

.overlayLogo {
  width: 100%;
}

.healthCheckText {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.newSolutionsLabel {
  color: #848484;
  font: 375 14px Plain, sans-serif;
}

.healthCheckTitle {
  color: #3e3e3e;
  font: 600 40px Plain, sans-serif;
  margin-top: 8px;
}

.healthCheckDescription {
  color: #3e3e3e;
  font: 375 22px/33px Plain, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: 16px;
}

.healthCheckOffer {
  color: #3e3e3e;
  font: 375 1rem / 1.3rem Plain, sans-serif;
  margin-top: 16px;
}

.strikethrough {
  text-decoration: line-through;
}

.claimNowButton {
  background-color: #048a24;
  border: none;
  border-radius: 8px;
  color: #fff;
  font: 600 18px/150% Plain, sans-serif;
  padding: 16px;
  margin-top: 32px;
  cursor: pointer;
}
.claimNowButton:hover{
  background-color: #005A24;
}

.demoCountdownContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 100%;
}

.demoCountdown {
  padding: 24px 60px;
  background-color: #117170;
  border-radius: 16px;
  width: fit-content;
}

.countdownContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.countdownIcon {
  width: 24px;
}

.countdownText {
  color: #fff;
  font: 400 1.2rem/150% Inter, sans-serif;
  margin-bottom: 0px;
}

.daysLeft {
  font-weight: 700;
}

.solutionsBackground {
  position: absolute;
  bottom: 150px;
  left: 0px;
  z-index: 0;
}

.painPointsSection {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin: 72px 0;
  padding: 0 60px;
}

.containerPP {
  width: 100%;
  max-width: 1325px;
}

.contentPP {
  display: flex;
  gap: 140px;
  align-items: center;
}

.textContentPP {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 67%;
}

.headerPP {
  display: flex;
  flex-direction: column;
  line-height: 150%;
}

.label {
  color: #848484;
  font: 375 14px Plain, sans-serif;
}

.titlePP {
  color: #3e3e3e;
  font: 600 40px Plain, sans-serif;
}

.descriptionPP {
  color: #3e3e3e;
  font: 375 22px/33px Plain, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: 16px;
}

.cardGridPP {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 16px;
}

.imageWrapperPP {
  width: 33%;
}

.imagePP {
  aspect-ratio: 0.93;
  object-fit: cover;
  width: 100%;
}

.PSnode{
  margin: 20px;
  padding: 24px 60px;
  background-color: #c8c8c8;
  border-radius: 16px;
  color: white;
}

@media (max-width: 991px) {
  .painPointsSection {
    margin-top: 40px;
    padding: 0 20px;
  }

  .contentPP {
    flex-direction: column;
  }

  .textContentPP,
  .imageWrapperPP {
    width: 100%;
  }

  .cardGridPP {
    flex-direction: column;
  }

  .imagePP {
    margin-top: 40px;
  }

  .header {
    max-width: 100%;
    padding: 20px;
  }

  .logoContainer {
    max-width: 100%;
    padding-left: 20px;
    margin-bottom: 40px;
  }

  .headerContent {
    max-width: 100%;
    margin-top: -200px;
    flex-direction: column;
  }

  .greeting {
    font-size: 40px;
  }

  .headerImage {
    margin-top: 40px;
  }

  .mainSection {
    padding: 0 20px;
  }

  .contentWrapper {
    flex-direction: column;
  }

  .dashboardImage,
  .textContent {
    width: 100%;
  }

  .dashboardImage {
    margin-top: 40px;
  }

  .solutionsSection {
    padding: 40px 20px;
  }

  .solutionsGrid {
    flex-wrap: wrap;
  }

  .healthCheckSection {
    margin-top: 40px;
    padding: 0 20px;
  }

  .healthCheckContent {
    flex-direction: column;
  }

  .imageContainer,
  .healthCheckText {
    width: 100%;
  }

  .imageContainer {
    margin-bottom: 40px;
  }

  .demoCountdown {
    margin-top: 40px;
    padding: 24px 20px;
  }

  .countdownContent {
    flex-wrap: wrap;
  }
}

@keyframes heartbeatAnimation {
  0%, 20% {
    transform: scale(1);
  }
  2% {
    transform: scale(1.09);
  }
  4% {
    transform: scale(1);
  }
  6% {
    transform: scale(1.07);
  }
  8% {
    transform: scale(1);
  }
  20%, 100% {
    transform: scale(1);
  }
}

.heartbeatAnimation {
  animation: heartbeatAnimation 8.5s ease-in-out infinite;
  transform-origin: center center;
}

.centered {
  margin: 32px auto;
}
.pdAccordion button:not(.collapsed){
  background-color: #FAFAFA!important;
  box-shadow: none!important;
  color: var(--primary-gray);
  font-weight: 600;
}
.pdAccordion button{
  color: var(--primary-gray);
  font-weight: 600;
}
.pdAccordion {
  border: none!important;
  width: 100%!important;
}
.pdAccordion .accordionBox{
  background-color: #FAFAFA;
  border: none;
  color: var(--primary-gray);
}
.dangerActionButton {
  align-items: center;
  background-color: var(--dark-danger);
  border: none;
  border-radius: 56px;
  color: white;
  text-align: left;
  padding: 12px 24px 12px 16px;
  font-size: 16px;
  gap: 8px;
  display: flex;
}
.dangerActionButton .icon {
  height: 32px;
  width: auto;
  animation: fadeInOutTopBottom 4.5s ease-in-out infinite;
}

@keyframes fadeInOutTopBottom {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  10%, 90% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.stickyButton{
  position: sticky;
  top: 0;
  z-index: 99;
  margin-top: -132px;
}