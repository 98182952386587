.select-wrapper {
    position: relative;
  }

  .select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-color: white;
  }

  .arrow {
    font-size: 12px;
  }

  .selected-pills {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
  }

  .pill {
    background-color: #e8f4ea;
    border-radius: 16px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .pill button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-left: 5px;
    color: #666;
  }

  .options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 200px;
    overflow-y: auto;
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
  }

  .options-list li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .options-list li:hover {
    background-color: #f5f5f5;
  }

  .options-list input[type="checkbox"] {
    margin-right: 10px;
  }

  .options-list input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #005A24;
    border-radius: 3px;
    outline: none;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
  }

  .options-list input[type="checkbox"]:checked {
    background-color: #005A24;
  }

  .options-list input[type="checkbox"]:checked::before {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 12px;
  }