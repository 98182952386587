.item__filterMultiCheckbox {
  padding: 4px;
  border-radius: 8px;
  background: rgba(229, 242, 233, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: rgba(66, 66, 66, 1);
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
.delete__multiCheckbox {
  margin-left: 10px;
}

.container__buttonFilterMultiCheckbox {
  display: flex;
  column-gap: 4px;
  overflow-x: scroll;
  max-width: 600px;
  padding: 16px;
  height: 56px;
  /* margin-right: 16px; */
}

.dropdown-toggleButtonMultiCheckbox {
  display: flex;
  align-items: center;
  /* padding: 16px; */
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(224, 224, 224, 1);
  padding-top: 0px;
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-grow: 1;
  width: 100%;

  /* background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="none" stroke="%23333" stroke-width="1" d="M0 0l5 5 5-5"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 5px; */
  
}
.dropdown-toggleButtonMultiCheckbox::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin: 10px;
}