@import '../../assets/fonts/fontsInterface.css';

.premium-request__modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 24, 39, 0.5);
  z-index: 1000;
  font-family: 'Plain-Regular', sans-serif;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.premium-request__modal-content {
  background: white;
  width: 100%;
  max-width: 677px;
  min-width: 320px;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}

.premium-request__modal-header {
  padding: 18px 32px;
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.premium-request__modal-title {
  font-size: 24px;
  margin: 0;
  line-height: 1.2;
}

.premium-request__modal-close {
  cursor: pointer;
  color: #828282;
  background: none;
  border: none;
  font-size: 22px;
  padding: 5px;
  transition: color 0.2s ease;
}

.premium-request__modal-close:hover {
  color: #333;
}

.premium-request__modal-body {
  display: grid;
  gap: 32px;
  overflow-y: auto;
  padding: 24px 32px;
}

.premium-request__modal-main {
  display: grid;
  gap: 16px;
  width: 100%;
}

.premium-request__modal-main-card {
  border: 1px solid #117170;
  padding: 20px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.premium-request__modal-main-card:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.premium-request__modal-main-card.selected {
  background-color: #e5f2e9;
  border-color: #005a24;
}

.premium-request__modal-card-item__container {
  display: flex;
  gap: 16px;
  align-items: center;
}

.premium-request__modal-card-title__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: #e5f2e9;
  border-radius: 50%;
}

.premium-request__modal-card-icon {
  padding: 10px;
}

.premium-request__modal-card-icon path {
  fill: #005a24;
}

.premium-request__modal-main-card.selected .premium-request__modal-card-icon {
  background-color: #005a24;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.premium-request__modal-main-card.selected
  .premium-request__modal-card-icon
  path {
  fill: white;
}

.premium-request__modal-card-title {
  font-size: 16px;
  font-weight: 600;
}

.premium-request__modal-card-description {
  font-size: 14px;
  max-width: 490px;
  margin: 8px 0 0;
}

.premium-request__modal-footer {
  display: flex;
  padding: 20px 32px;
  justify-content: space-between;
  border-top: 1px solid #cccccc;
}

.premium-request__modal-footer button {
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.premium-request__modal-footer_cancel-button {
  background: white;
  color: #000;
  border: 1px solid #8b8b8b;
}

.premium-request__modal-footer_cancel-button:hover {
  background-color: #f3f4f6;
}

.premium-request__modal-footer_confirm-button {
  background: #048a24;
  color: white;
  border: none;
  margin-left: auto;
}

.premium-request__modal-footer_confirm-button:hover {
  background-color: #036d1d;
}

.premium-request__modal-footer_confirm-button:disabled {
  background-color: #bbb;
  cursor: not-allowed;
}

.premium-request__modal-confirmation {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 16px;
  gap: 16px;
  flex-wrap: wrap;
  text-align: center;
}

.premium-request__modal-confirmation__header-icon {
  margin-bottom: 16px;
  color: #36a13b;
  font-size: 48px;
}

.premium-request__modal-confirmation__header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.premium-request__modal-confirmation__subheader {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}

.premium-request__modal-confirmation__number {
  font-size: 36px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .premium-request__modal-content {
    width: 90%;
    min-width: unset;
  }

  .premium-request__modal-header,
  .premium-request__modal-body,
  .premium-request__modal-footer {
    padding: 16px;
  }

  .premium-request__modal-title {
    font-size: 20px;
  }

  .premium-request__modal-footer {
    flex-direction: column-reverse;
    gap: 16px;
  }

  .premium-request__modal-footer button {
    width: 100%;
  }
}
