.App {
  font-family: sans-serif;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.container__imgSlider {
  display: flex;
  max-width: 827px;
  overflow-x: auto;
  scroll-behavior: smooth;
  transition: scroll 0.3s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.imgSlider__icon {
  font-size: 60px;
  color: #ffff;
  margin-bottom: 20px;
}
.nav-btn {
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 6px;
  width: 30px;
  margin: 5px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 22px;
}
.nav-btn:hover {
  color: rgb(108, 117, 125) !important;
}
.nav-btn:disabled {
  color: #ffffff5e !important;
}
.nav-btn:disabled:hover {
  color: #ffffff5e !important;
}
.appName {
  color: #ffff;
  font-size: 18px;
}
.imgSlider__img {
  margin: 20px;
  text-align: center;
  cursor: pointer;
}