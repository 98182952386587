.container__infoUser {
  background: #f7f7f5;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.container__welcome {
  width: 100%;
}
.skeleton__animation {
  background-color: #f8f1f1;
  animation: skeleton 2s linear infinite alternate;
}
.skeleton__animation__text {
  color: #f8f1f1;
  animation: skeletonAnimation 2s linear infinite alternate;
}
.welcome__btn {
  height: 10px;
  width: 20px;
  background-color: black;
}
.welcome {
  height: 42px;
} 
.imgAndName__img {
  border-radius: 50%;
  height: 190px;
  width: 190px;
  margin-top: 10px;
}
.imgAndName__name {
  border-radius: 3px;
  width: 189px;
  height: 40px;
  margin-top: 10px;
}
.infoText__role, .infoText__license, .infoText__subdomains, .infoText__date {
  border-radius: 3px;
  width: 131px;
  height: 18px;
  margin-top: 20px;
}
.container__banner {
  position: relative;
  background-position: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  border-radius: 2px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  border: 1px solid white;
  background: #f7f7f5;
  padding: 20px;
  margin-bottom: 6px;
}
.banner__leftArrow, .banner__rightArrow, .product__leftArrow, .product__rightArrow  {
  width: 20px;
  height: 20px;
}
.banner__slider {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px 30px;
  -webkit-box-align: center;
  align-items: center;
  row-gap: 20px;
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  height: 400px;
  width: 800px;
}
.container__pdUniversity {
  width: 600px;
}
.pdUniversity__grid {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 21px;
  grid-row-gap: 16px
}
.grid {
  margin-right: 16px;
  width: 170px;
  height: 50px;
}
.premiumService__title, .supportSummary__title {
  width: 180px;
  height: 30px;
  margin-bottom: 50px;
  border-radius: 6px;
}
.pdUniversity__title {
  width: 180px;
  height: 30px;
  margin-bottom: 10px;
  border-radius: 6px;
}
.product__title {
  width: 180px;
  height: 30px;
  margin-bottom: 10px;
  border-radius: 6px;
}
.container__product{
  height: 229px;
  width: 48%;
  background-color: #f7f7f5;
  border-radius: 0.3rem;
  padding: 18px 32px 32px 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.container__pdUniversity{
  width: auto;
  background-color: #f7f7f5;
  border-radius: 0.3rem;
  padding: 32px 32px 32px 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.product__grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product__banner {
  width: 146px;
  height: 111px;
}
.container__premiumService {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #f7f7f5;
  padding: 20px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
}
.container__supportSummary {
  width: 48%;
  height: 229px;
  background-color: #f7f7f5;
  padding: 20px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 39px;
}
.premiumService__grid {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 15px;
  width: 100%;
  margin-top: 49px;
}
.supportSummary__grid {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 15px;
  width: 100%;
  margin-bottom: 30px;
}
.supportSummary__grid {
  column-gap: 50px;
}
.grid__premiumService {
  border: 1px solid rgb(231, 234, 237);
  border-radius: 0.25rem;
  height: 84px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: calc(16.6667%);
  min-width: 192px;
}
.grid__supportSummary {
  border: 1px solid rgb(231, 234, 237);
  border-radius: 0.25rem;
  height: 59px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: calc(16.6667%);
  min-width: 108px;
}
.premiumService__tablet {
  width: 100%;
  height: 20px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.container__infoUse {
  flex: 1 1;
  height: 100%;
  background-color: white;
  padding: 8px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  border-radius: 2px;
  height: 94px;
}

@keyframes skeleton {
  0% {
    background-color: #f8f1f1;
  }
  100% {
    background-color: #d8d5d5;
  }
}
@keyframes skeletonAnimation {
  0% {
    color: #f8f1f1;
  }
  100% {
    color: #d8d5d5;
  }
}