.logout-container{

  width: 100%;
}

.logout_header {
  padding-left: 1.5rem;
  padding-right: 1.5rem; 
  padding-top: 1.5rem; 
  width: 100%; 
  max-width: 80rem; 
  
  @media (min-width: 640px) { 
    padding-top: 2.5rem; 
   }
  
  @media (min-width: 1024px) { 
    padding-left: 2rem;
    padding-right: 2rem; 
    grid-column: span 2 / span 2; 
    grid-column-start: 1; 
    grid-row-start: 1; 
   }
}

.logout_logo {
  width: auto; 
  height: 2.5rem; 

  @media (min-width: 640px) { 
    height: 3rem; 
  }
}

.logout-message_container {
  display: flex;
  align-items: center;
  width: 100%;
}
 
.logout-message_error {
  font-size: 1rem;
  line-height: 1.5rem; 
  font-weight: 600; 
  line-height: 2rem; 
  color: #048a24; 
}

.logout-message_header {
  margin-top: 1rem; 
  font-size: 1.875rem;
  line-height: 2.25rem; 
  font-weight: 700; 
  letter-spacing: -0.025em; 
  color: #111827; 

  @media (min-width: 640px) { 
    font-size: 3rem;
  line-height: 1; 
  }
}

.logout-message_paragraph {
 
  font-size: 1rem;
  line-height: 1.5rem; 
  line-height: 1.75rem; 
  color: #4B5563; 
}

.logout-image_container {
  flex: 1;
  height: 100vh;
}

.logout-image {
  object-fit: cover;  
  width: 100%; 
  height: 100%; 

}

.logout-login_button {
  margin: 15px 0px;
  background-color: #005a24;
  color: #FFF;
  border-radius: 15px;
  width: 100%;
  padding: 7px 55px;
  border: none;
  cursor: pointer;
}


@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.logout-container {
  width: 100%;
  height: 100vh;
  float: left;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.area-container {
  background: #33cf54;
  width: 100%;
  height: 100vh;
}

.elements-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.5);
    animation: animate 25s linear infinite;
    bottom: -150px;
  }

  & li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }

  & li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }

  & li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }

  & li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }

  & li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }

  & li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  & li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }

  & li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  & li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  & li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
}

.logout-icon_container {
  position: absolute;
  display: flex;
  column-gap: 20px;
}