.Layout {
  /* text-align: center */
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6e6e6e;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.content {
  display: flex;
  width: 100%;
  background-color: #e5f2e9;
}

.loading {
  display: flex;
  align-items: center;
}

.appbar {
  width: 100%;
  display: inline;
  font-size: 13px;
  background-color: #fff;
  height: 58px;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding-right: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  flex: none;
  box-sizing: border-box;
  z-index: 5;
  box-shadow: -1px 2px 2px #888888;
}

.logout-icon {
  width: 1em;
  color: #757575;
}

.appbar-profile-icon {
  width: 42px;
  height: 42px;
  border-radius: 18px;
  cursor: pointer;
}

.appbar-logout-active {
  animation: fadeIn ease 0.5s;
  position: relative;
  text-align: right;
  opacity: 1;
  top: 60px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.appbar-logout-button {
  font-size: 14px;
  padding: 8px 16px;
  margin-top: 5px;
  margin-right: 10px;
  width: 250px;
  cursor: pointer;
  border: none;
  animation-duration: 0.5s;
  background-color: #ffffff;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
}

.page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

.page {
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
}

.page .content {
  flex: 1 1 0;
}

.footer {
  padding: 20px;
  display: flex;
  font-size: 13px;
  margin: 20px;
  width: 100%;
  flex-shrink: 0;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




.wrapper {
  width: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 270px);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.column {
  width: 250px;
  padding-left: 10px;
  padding-right: 10px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  margin-bottom: 8px;
}


.logo-menu-container {
  padding: 16px;
  justify-content: space-between;
  float: left;
  display: flex;
  align-items: center;
}

.menu-link {
  text-decoration: none;
  color: white;
}

.menu-link-intro {
  text-decoration: none;
  color: #005a24;
}

.menu-links-intro {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.button-regular {
  padding: 8px 16px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  position: relative;
  display: inline-block;
  font-family: "Plain-Thin";
  font-weight: bold;
  font-size: 16px;
  z-index: 3;
  background-color: #06ac38;
  margin-right: 3px;
  color: white;
  outline: none;
  cursor: pointer;
}

.button-regular:disabled {
  background-color: #4b4f4f;
  opacity: 0.2;
}

.buttonstyle-regular-link {
  padding: 10px 16px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  position: relative;
  display: inline-block;
  font-family: "Plain-Thin";
  font-weight: bold;
  font-size: 16px;
  z-index: 3;
  background-color: #06ac38;
  margin-right: 3px;
  color: white;
  outline: none;
  cursor: pointer;
  border: 2px solid black;
}

.buttonstyle-regular-link,
.buttonstyle-regular-link:hover,
.buttonstyle-regular-link:focus,
.buttonstyle-regular-link:active {
  text-decoration: none;
  color: white;
}

header {
  height: 58px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  height: 58px;
  justify-content: space-between;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  list-style: none;
  margin: 0;
  position: relative;
}

.menu-items {
  position: relative;
  font-size: 16px;
  margin: 0;
}

.menu-items-user {
  position: relative;
  font-size: 16px;
  margin: 0;
  border-bottom: 1px solid rgb(163, 163, 163);
}

.menu-items-user a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  text-align: left;
  padding: 0.7rem 1rem;
  border-left: 4px solid transparent;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  border-left: 4px solid transparent;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items-user a:hover,
.menu-items button:hover {
  border-left: 4px solid rgb(4, 138, 36);
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

html,
body {
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.infoAppbutton {
  position: absolute;
  right: -15px;
  outline: none;
  border: none;
  background-color: transparent;
  color: #06ac38;
  font-size: 16px;
}

.feedbackIcon {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: transparent;
  border: none;
}

.feedbackButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  padding: 10px 15px;
  border: 1px solid gray;
  border-radius: 5px;
  font-weight: bold;
  width: 105px;
}

.feedbackButton:hover {
  color: rgb(6, 172, 56);
  border: 1px solid rgb(6, 172, 56);
}

.feedbackTextareaDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.feedbackButtonDiv {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.feedbackTextareaDiv textarea {
  margin: 20px 0px;
  border: 1px solid #808080b5;
  border-radius: 5px;
  padding: 20px;
}

.feedbackSubmitButton {
  background-color: rgb(6, 172, 56);
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  outline: none;
  margin-right: 15px;
  border: 1px solid rgb(6, 172, 56);
}

.feedbackSubmitButton:disabled {
  background-color: #e7e7e7 !important;
  border: 1px solid #e1e1e1;
}

.feedbackCloseButton {
  background-color: #ffffff;
  padding: 10px 20px;
  color: rgb(6, 172, 56);
  border-radius: 5px;
  outline: none;
  margin-right: 15px;
  border: 1px solid #afafaf;
}

.feedbackSpan {
  font-weight: bold;
  color: rgb(6, 172, 56);
}


.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
}
.side-menu svg{
  width: 20px;
  height: 20px;
}
.side-menu .activeItem{
background-color: #005A24;
  & span {
    color: #fafafafa;
  }
} 
.side-menu .activeItemSmall{
background-color: #005A24!important;
  & span {
    color: #fafafafa;
  }
} 
.side-menu span {
  font-size: 14px;
}

.content-wrapper {
  display: flex;
  flex: 1;
  position: relative;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: calc(100% - 68px);
}

/* .outlet-wrapper {
  flex: 1;
  overflow-y: auto;
} */
.outlet-wrapper {
  flex: 1 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
}
/* Banner styles */
.banner {
  width: 100%;
  padding: 10px 0;
  text-align: start;
  font-weight: bold;
  margin-bottom: 16px;
  border-radius: 8px;
  font-weight: 600;
  z-index: 0;
}

/* Navbar styles */
.navbar {
  width: 100%;
  padding: 10px 0;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}

