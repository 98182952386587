.unauthorized-container{

  width: 100%;
}

.unauthorized_header {
  padding-left: 1.5rem;
  padding-right: 1.5rem; 
  padding-top: 1.5rem; 
  width: 100%; 
  max-width: 80rem; 
  
  @media (min-width: 640px) { 
    padding-top: 2.5rem; 
   }
  
  @media (min-width: 1024px) { 
    padding-left: 2rem;
    padding-right: 2rem; 
    grid-column: span 2 / span 2; 
    grid-column-start: 1; 
    grid-row-start: 1; 
   }
}

.unauthorized_logo {
  width: auto; 
  height: 2.5rem; 

  @media (min-width: 640px) { 
    height: 3rem; 
  }
}

.unathorized-message_container {
  display: flex;
  align-items: center;
}
 
.unauthorized-message_error {
  font-size: 1rem;
  line-height: 1.5rem; 
  font-weight: 600; 
  line-height: 2rem; 
  color: #048a24; 
}

.unauthorized-message_header {
  margin-top: 1rem; 
  font-size: 1.875rem;
  line-height: 2.25rem; 
  font-weight: 700; 
  letter-spacing: -0.025em; 
  color: #111827; 

  @media (min-width: 640px) { 
    font-size: 3rem;
  line-height: 1; 
  }
}

.unathorized-message_paragraph {
 
  font-size: 1rem;
  line-height: 1.5rem; 
  line-height: 1.75rem; 
  color: #4B5563; 
}

.unathorized-image_container {
  flex: 1;
  height: 100vh;
}

.unathorized-image {
  object-fit: cover;  
  width: 100%; 
  height: 100%; 

}

.unathorized-login_button {
  margin: 15px 0px;
  background-color: #005a24;
  color: #FFF;
  border-radius: 15px;
  width: 100%;
  padding: 7px 55px;
  border: none;
  cursor: pointer;
}