.container__topLoading {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin-bottom: 20px;
	gap: 20px;
	justify-content: center;
	align-items: center;
}
.container__cardSkeletonTop {
	width: 200px;
	background-color: #e5e5e5;
	height: 250px;
	margin-bottom: 10px;
}
.skeleton__animation {
  background-color: #f8f1f1;
  animation: skeleton 2s linear infinite alternate;
}
@keyframes skeleton {
  0% {
    background-color: #f8f1f1;
  }
  100% {
    background-color: #d8d5d5;
  }
}