@import './assets/fonts/fontsInterface.css';
@import './globals.css';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Plain-Regular';
  min-width: 320px;
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
}

.rs-picker-daterange-header {
  display: none;
}
[data-testid='daterange-predefined-bottom'] {
  display: none !important;
}

[data-testid='picker-popup'] {
  z-index: 100000;
}

.rs-picker-popup.rs-picker-popup-daterange .rs-calendar:first-child {
  border-right: none !important;
}

.rs-picker-toolbar {
  border-top: none !important;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px var(--secondary-color) !important;
  border-radius: 50%;
  color: var(--secondary-color) !important;
}

.rs-calendar-table-cell-content:hover {
  background-color: inherit !important;
  color: var(--secondary-color) !important;
}

.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: inherit !important;
  color: #005a24 !important;
  z-index: 1;
}

/* .rs-picker-popup .rs-calendar .rs-calendar-table-cell-in-range:hover .rs-calendar-table-cell-content {
  background-color: #E5F2E9 !important;
  color: #005A24 !important;
  z-index: 1;
  border-radius: 0 !important;
  box-shadow: 0px 0px 1px 2px #E5F2E9;
} */

.rs-btn-primary {
  color: transparent !important;
  background-color: transparent !important;
  position: relative;
  z-index: 1;
  width: 200px;
}

.rs-btn-primary::before {
  content: 'Apply';
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
  position: absolute;
  top: 0px;
  right: 12px;
  z-index: 2;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #005a24 !important;
  border-radius: 50%;
  font-weight: bold;
}

.rs-calendar-table-cell-in-range::before {
  background-color: #e5f2e9 !important ;
}

.rs-calendar-table-cell-in-range {
  position: relative;
}

.rs-calendar-table-cell-selected::before,
.rs-calendar-table-cell-in-range::before {
  margin-top: 0px !important;
  height: 32px !important;
}

.rs-calendar-header-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #293050 !important;
}

.rs-calendar-table-header-cell-content {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #7b8199 !important;
}

.rs-picker-popup {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.rs-input-group {
  border: 1px solid #e0e0e0 !important;
  height: 100%;
  padding: 2px 4px;
  border-radius: 8px !important;
  flex-direction: row-reverse;
}

.rs-input-group-md.rs-input-group > .rs-input {
  font-weight: 600;
  color: #424242;
}

.rs-input-group input.rs-input ~ .rs-input-group-addon,
.rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon {
  color: transparent;
}

.rs-picker-daterange .rs-input-group-addon .rs-btn-close {
  width: 24px;
  height: 24px;
}

.rs-picker-toggle-wrapper {
  width: 100%;
}
