.center {
    width: 100px;
    height: 100px;
}

.circle {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

.wave {
    background-color: #3f68c5;
    position: absolute;
    height: 200%;
    width: 200%;
    border-radius: 35%;
    left: -50%;
    transform: rotate(360deg);
    transition: all 5s ease;
    animation: wave 30s linear infinite;
}

.waveText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
    color: white;
    position: absolute;
    text-shadow: 0px 0px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

@keyframes wave {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(3600deg);
    }
}

.circle:hover .wave {
    top: 0%;
}

.background-wrap {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    height: 95px;
    width: 95px;
}

/* KEYFRAMES */

@-webkit-keyframes animateBubble {
    0% {
        margin-top: 100px;
    }

    100% {
        margin-top: -30%;
    }
}

@-moz-keyframes animateBubble {
    0% {
        margin-top: 100px;
    }

    100% {
        margin-top: -30%;
    }
}

@keyframes animateBubble {
    0% {
        margin-top: 100px;
    }

    100% {
        margin-top: -30%;
    }
}

@-webkit-keyframes sideWays {
    0% {
        margin-left: 0px;
    }

    100% {
        margin-left: 100px;
    }
}

@-moz-keyframes sideWays {
    0% {
        margin-left: 0px;
    }

    100% {
        margin-left: 100px;
    }
}

@keyframes sideWays {
    0% {
        margin-left: 0px;
    }

    100% {
        margin-left: 100px;
    }
}

/* ANIMATIONS */

.x1 {
    -webkit-animation: animateBubble linear infinite, sideWays 2s ease-in-out infinite alternate;
    -moz-animation: animateBubble linear infinite, sideWays 2s ease-in-out infinite alternate;
    animation: animateBubble linear infinite, sideWays 2s ease-in-out infinite alternate;
}

/* OBJECTS */

.bubble {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    -webkit-box-shadow: 0 0px 2px rgba(255, 255, 255, 0.9), inset 0px 5px 5px 5px rgba(255, 255, 255, 0);
    -moz-box-shadow: 0 0px 2px rgba(255, 255, 255, 0.9), inset 0px 5px 5px 5px rgba(255, 255, 255, 0);
    box-shadow: 0 0px 2px rgba(255, 255, 255, 0.9), inset 0px 5px 5px 5px rgba(255, 255, 255, 0);

    height: 3px;
    position: absolute;
    width: 3px;
}