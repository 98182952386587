#OnBoarding-container {
  height: calc(100vh - 59px);
  overflow-x: hidden;
  width: 100%;
  overflow-y: hidden;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 0px;
}

#OnBoarding-title {
  font-size: 40px;
  margin: 0;
  margin-left: 15px;
}

#OnBoarding-container-key {
  display: flex;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 100%;
  justify-content: space-between;
}

#OnBoarding-container-key div {
  display: flex;
  align-items: center;
  width: calc(100% - 417px);
  animation-name: addKeyAnimation;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

.removeKeyContainer {
  animation-name: removeKeyAnimation !important;
}

.onboardingIconLabelDiv {
  display: flex;
  justify-content: center;
}

.onboardingRedIcon {
  color: red;
  font-size: 24px;
  margin-left: 8px;
}

.onboardingsubdomainKeyLabelDiv {
    color: #035F7F;
    font-weight: bold;
    margin-left: 5px;
}

@keyframes addKeyAnimation {
  0% {
    opacity: 0;
    transform: rotateX(90deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes removeKeyAnimation {
  0% {
    opacity: 1;
    transform: rotateX(0deg);
  }

  100% {
    opacity: 0;
    transform: rotateX(90deg);
  }
}

.encrypted {
  -webkit-text-security: disc;
}

.encrypted-div {
  width: 100% !important;
  position: relative;
}

.encrypted-div button {
  position: absolute;
  outline: none;
  background-color: transparent;
  border: 0px;
  right: 6px;
  top: 12px;
}

.encrypted-div input {
  padding-right: 35px !important;
  width: 100% !important;
}

#OnBoarding-container-key span {
  background-color: #035F7F;
  color: white;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#OnBoarding-container-key input {
  margin: 0px;
  color: black;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  border: 2px solid #035F7F;
  border-radius: 5px;
  text-align: start;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

#OnBoarding-container-right-step2-right .button-regular {
  background-color: #035F7F;
}

#OnBoarding-container-right-step2-right .button-regular:disabled {
  background-color: #4b4f4f;
  opacity: 0.2;
}

.OnBoarding-container-right-button {
  background-color: #035F7F !important;
}

#OnBoarding-container-right-button-submit {
  background-color: #035F7F;
}

#OnBoarding-container-right-button-submit:disabled {
  background-color: #4b4f4f;
  opacity: 0.2;
  cursor: default;
}

#OnBoarding-container-right-button-add-container {
  display: flex;
  width: 100%;
  align-items: center;
}

#OnBoarding-container-right-button-add {
  width: 94px;
  height: fit-content;
  background-color: #035F7F;
  margin-left: 15px;
}

#OnBoarding-container-right-button-add:disabled {
  background-color: #4b4f4f;
  opacity: 0.2;
  cursor: default;
}

#OnBoarding-title-container {
  border-bottom: 1px solid #4b4f4f;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

#OnBoarding-title-container-time {
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  font-size: 12px;
  margin-left: 25px;
}

#OnBoarding-container-left {
  margin: 0;
  height: calc(100vh - 59px);
  overflow: hidden;
  display: flex;
  margin: 0;
  height: calc(100vh - 150px);
  overflow: hidden;
  position: relative;
}

#OnBoarding-container-left-waves {
  background-color: rgb(201 223 231);
  width: fit-content;
  margin: 20px;
  padding: 40px 20px;
  height: calc(100% - 40px);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  border: 4px solid rgb(1 49 65);
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
}

#OnBoarding-button-container {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.OnBoarding-waves-title {
}

.OnBoarding-waves-title label {
  color: white;
  text-shadow: 0px 0px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  margin-top: 15px;
}

#OnBoarding-container-right {
  padding-top: 20px;
  padding-right: 20px;
  width: 100%;
  overflow-y: auto;
  margin: 0;
  height: calc(100vh - 150px);
  position: relative;
}

.Email {
  display: flex;
  width: 100%;
}

.Email > input {
  width: calc(70% - 24px);
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.Email #Dropdown {
  width: 30%;
}

.Email #Dropdown #Dropdown-container input {
  border-radius: 0px;
  background-color: #035F7F;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.OnBoarding-container-right-chevron-div {
}

.OnBoarding-container-right-chevron-div #Chevron-container-title {
  border: 1px solid;
  width: 100%;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  background-color: #035F7F;
  color: white;
  border-radius: 4px;
  border: 2px solid black;
}

.OnBoarding-container-right-buttons-div {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  width: 100%;
}

.OnBoarding-submit-container-right-buttons-div {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  width: 100%;
}

#OnBoarding-container-right-DivUserPicture {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  border: 8px solid #035F7F;
  background: round;
  box-shadow: inset 0px 0px 6px rgb(0 0 0);
  background-size: cover;
}

#OnBoarding-container-right-DivUserPicture-file {
  display: none;
}

#OnBoarding-container-right-DivUserPicture-button {
  width: fit-content;
  align-items: center;
  justify-content: center;
  height: fit-content;
  font-size: 14px;
  display: flex;
  margin: 20px 0px;
  width: 320px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid;
  padding: 5px 10px;
  background-color: white;
  color: #035F7F;
  border: 1px solid #035F7F;
  border-radius: 4px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  cursor: pointer;
  background-color: #035F7F;
  color: white;
  border: 1px solid white;
}

#OnBoarding-container-right-step2 {
  display: flex;
  margin: 20px;
}

#OnBoarding-container-right-step2-left {
  width: 35%;
  justify-content: center;
  align-items: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

#OnBoarding-container-right-step2-right {
  /* width: 65%; */
  width: 100%;
  justify-content: center;
  align-items: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.normalRow .PhoneInput {
  width: 100%;
}

.normalRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.normalRow input {
  color: black;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0px;
  cursor: pointer;
  outline: none;
  border: 2px solid #035F7F;
  border-radius: 5px;
  text-align: start;
}

#OnBoarding-container #Dropdown-container input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

#OnBoarding-container #Dropdown-chevron {
  background-color: #035F7F;
}

.normalRow input:disabled {
  cursor: default;
}

.GravatarDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.GravatarDiv input {
  display: flex;
  width: 70%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.GravatarDiv span {
  width: 30%;
  border: 2px solid #035F7F;
  height: 48px;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #035F7F;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block !important;
  line-height: 48px;
}

.normalRow select {
  color: black;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0px;
  cursor: pointer;
  outline: none;
  border: 2px solid #035F7F;
  border-radius: 5px;
  text-align: start;
  background-color: white;
  padding: 13px;
}

.normalRow textarea {
  color: black;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0px;
  cursor: pointer;
  outline: none;
  border: 2px solid #035F7F;
  border-radius: 5px;
  text-align: start;
}

.normalRow label {
  font-weight: bold;
}

.normalRow2Items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.normalRow2Items input {
  color: black;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0px;
  cursor: pointer;
  outline: none;
  border: 2px solid #035F7F;
  border-radius: 5px;
  text-align: start;
}

.normalRow2Items input:disabled {
  cursor: default;
}

.normalRow2Items select {
  color: black;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0px;
  cursor: pointer;
  outline: none;
  border: 2px solid #035F7F;
  border-radius: 5px;
  text-align: start;
  background-color: white;
  padding: 13px;
}

.normalRow2Items textarea {
  color: black;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0px;
  cursor: pointer;
  outline: none;
  border: 2px solid #035F7F;
  border-radius: 5px;
  text-align: start;
}

.normalRow2Items label {
  font-weight: bold;
}

.normalRow2ItemsFirst {
  width: 30%;
}

.normalRow2ItemsSecond {
  width: calc(70% - 15px);
  margin-left: 15px;
}

#OnBoarding-container-right-step3-bottom-div-users {
  min-height: 170px;
  border: 3px solid #035F7F;
  border-radius: 10px;
  margin: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  flex-wrap: wrap;
  max-height: 1000px;
  overflow-y: scroll;
}

.OnBoarding-container-right-DivUserPicture-mini-div {
  padding: 20px;
  width: fit-content;
  position: relative;
}

.OnBoarding-container-right-DivUserPicture-mini-div-closeIcon {
  position: absolute;
  right: 30px;
  color: black;
  font-size: 18px;
  background-color: white;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.OnBoarding-container-right-DivUserPicture-mini-div-warningIcon {
  position: absolute;
  left: 30px;
  color: red;
  font-size: 18px;
  background-color: white;
  height: 22px;
  width: 22px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.OnBoarding-container-right-DivUserPicture-warningText {
  width: 100%;
  text-align: initial;
  margin: 0px 30px;
  color: red;
}

.OnBoarding-container-right-description-text {
  font-weight: bold;
  font-style: italic;
  font-size: 13px;
}

.OnBoarding-container-right-DivUserPicture-mini {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #035F7F;
  background: round;
  box-shadow: inset 0px 0px 6px rgb(0 0 0);
}

.OnBoarding-Incident-container-title {
  border-bottom: 1px solid black;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.OnBoarding-SeparationText {
  width: 100%;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
  text-align: initial;
  font-weight: bold;
}

.OnBoarding-Incident-container-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.OnBoarding-Incident-container-div-inner {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#OnBoarding-container-right-step2-right-button-top-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

#OnBoarding-container-right-step2-right-button-top-container-link {
  color: blue;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  margin-bottom: 12px;
}

#OnBoarding-container-right-step2-right-button-top-container-link:hover {
  text-decoration: underline;
}

.OnBoarding-container-right-schedule-row {
  display: flex;
  color: black;
  width: 100%;
  font-family: sans-serif;
  text-decoration: none;
  font-size: 16px;
  margin: 10px 0px;
  cursor: pointer;
  outline: none;
  border: 2px solid #035F7F;
  border-radius: 5px;
  text-align: start;
  background-color: white;
}

.OnBoarding-container-right-schedule-row-name {
  width: 300px;
  padding: 10px;
  background-color: #035F7F;
  color: white;
  display: flex;
  justify-content: center;
}

.OnBoarding-container-right-schedule-row-schedule {
  margin: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.OnBoarding-container-right-schedule-row-schedule-row {
  width: calc(100% - 300px);
  justify-content: space-between;
  display: flex;
}

.OnBoarding-container-right-schedule-row-exit {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10px;
  color: #035F7F;
  font-size: 18px;
  background-color: white;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
#OnBoarding-container-right input::-webkit-outer-spin-button,
#OnBoarding-container-right input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#OnBoarding-container-right input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 1150px) {
  #OnBoarding-container-right-step2 {
    flex-direction: column;
  }

  #OnBoarding-container-right-step2-left {
    width: 100%;
  }

  #OnBoarding-container-right-step2-right {
    width: 100%;
  }

  .OnBoarding-Incident-container-div {
    flex-direction: column;
  }

  .OnBoarding-Incident-container-div-inner {
    width: 100%;
  }

  .OnBoarding-container-right-schedule-row {
    flex-direction: column;
  }

  .OnBoarding-container-right-schedule-row-name {
    width: 100%;
  }

  .OnBoarding-container-right-schedule-row-schedule-row {
    width: 100%;
  }

  #OnBoarding-container-right-DivUserPicture {
    max-width: 320px;
    max-height: 320px;
    width: 50vw;
    height: 50vw;
  }
}

@media (max-width: 600px) {
  #OnBoarding-container-left-waves {
    display: none;
  }
}

#Onboarding-loader {
  position: absolute;
  z-index: 100;
  width: 100%;
  background-color: #ffffff73;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Onboarding-FAQ {
display: flex;
justify-content: end;
align-items: center;
margin-bottom: 35px;
}

#Onboarding-FAQIcon {
cursor: pointer;
color: #035F7F;
font-size: 16px;
margin-left: 10px;
}


#Onboarding-loader .loading-spinner {
  border-top: 10px solid rgb(1, 49, 65);
}

.Onboarding-error-text {
  color: red;
}

#modalContainerErrorOnboardingDiv {
  width: calc(80vw - 40px);
  border: 1px solid black;
  margin: 40px;
}

#modalContainerErrorOnboardingDivTitles {
  background-color: red;
  color: white;
  padding: 25px;
  display: flex;
}

#modalContainerErrorOnboardingDivTitles div {
  width: 33%;
}

#modalContainerErrorOnboardingDivRow {
  display: flex;
  text-align: center;
}

#modalContainerErrorOnboardingDivRow div {
  width: 33%;
}

#modalContainerErrorOnboardingDivRow div div {
  width: calc(100% - 50px);
}

.modalContainerErrorOnboardingDivBubble {
  border: 1px solid;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalContainerErrorOnboardingDivColorCircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid black;
}

.modalContainerErrorOnboardingDivColorSpan {
  display: flex;
}

.modalContainerErrorOnboardingDivColorSpan div {
  margin: 0px 10px;
}

#modalContainerErrorOnboardingDivColorSpanContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.Onboarding-container-modal-chevron {
  width: calc(100% - 20px);
  margin: 0px 10px;
  margin-top: 10px;
}

.Onboarding-container-modal-chevron #Chevron-container-title {
  border: 1px solid;
  width: 100%;
  display: flex;
  padding: 10px 20px;
  align-items: center;
  background-color: #035F7F;
  color: white;
  border-radius: 4px;
  border: 2px solid black;
}

.modalContainerErrorOnboardingDiv {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.modalContainerErrorOnboardingDiv .modalContainerErrorOnboardingDivContainer {
  width: calc(33% - 44px);
}

.modalContainerErrorOnboardingDivContainer {
  display: flex;
  margin: 20px;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  color: white;
}

#modalContainerErrorOnboardingDivC {
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: fit-content;
  max-height: 70vh;
}

.modalContainerErrorOnboardingDivContainerInner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 0px;
}

.modalContainerErrorOnboardingDivContainerText {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.modalContainerErrorOnboardingDivContainerTextTitle {
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px 0px;
  width: 100%;
}

.modalContainerErrorOnboardingDivContainerTextMessage {
  margin-top: 10px;
  margin-bottom: 10px;
}

.modalContainerErrorOnboardingDivContainerTextCode {
}

.modalContainerErrorOnboardingDivContainerTextCodeInner {
  font-weight: bold;
}

.rowInputButton {
  display: flex;
  align-items: center;
  width: 100%;
}

.rowInputButton input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rowInputButton button {
  border: 0px;
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 48px;
}

.ADiv {
  padding: 10px 7px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  position: relative;
  display: inline-block;
  font-family: "Plain-Thin";
  font-weight: bold;
  font-size: 16px;
  background-color: #035F7F;
  color: white;
  outline: none;
  cursor: pointer;
  height: 48px;
}

.ADivInput {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

#step1TableDiv {
  width: calc(100% - 6px);
  display: flex;
  flex-direction: column;
  border: 3px solid #035F7F;
  border-radius: 9px;
  height: 200px;
  overflow-y: auto;
}

#step1TableDiv div {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

#step1TableDiv div button {
  border: 0px;
  outline: none;
  background-color: transparent;
}

#step1TableDiv div:nth-child(odd) {
  background-color: #e9eaea;
}

#step1TableDiv div svg {
  color: red;
  cursor: pointer;
}

#step1FormAndButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
}

#step1FormContainer {
  width: 100%;
}

#OnBoarding-container-email {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
}

#OnBoarding-container-email div {
  display: flex;
  align-items: center;
  width: 100%;
  animation-name: addKeyAnimation;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  margin-top: 8px;
}

#OnBoarding-container-email label {
  background-color: #035F7F;
  color: white;
  height: 48px;
  width: 150px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#OnBoarding-container-email input {
  margin: 0px;
  color: black;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  border: 2px solid #035F7F;
  border-radius: 5px;
  text-align: start;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

@media (max-width: 1200px) {
  .modalContainerErrorOnboardingDiv .modalContainerErrorOnboardingDivContainer {
    width: calc(50% - 44px);
  }
}

@media (max-width: 700px) {
  .modalContainerErrorOnboardingDiv .modalContainerErrorOnboardingDivContainer {
    width: calc(100% - 44px);
  }
}
