.skeleton__animation {
  background-color: #f8f1f1;
  animation: skeleton 2s linear infinite alternate;
}

.skeleton__animation__header {
  background-color: #117170;
  animation: skeleton 2s linear infinite alternate;
  width: 30%;
}
.client-delivery__skeleton {
  width: 100%;
  height: 300px;
  background-color: #f8f1f1;
  animation: skeleton 2s linear infinite alternate;
}
