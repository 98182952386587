.solutionCard {
  flex: 1;
  max-width: 309px;
  border-radius: 16px;
  box-shadow: 0 0 1px 0 rgba(12, 26, 75, 0.24),
    0 3px 8px -1px rgba(50, 50, 71, 0.05);
  background-color: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconWrapper {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #e5f2e9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.solutionIcon {
  width: 32px;
  height: 32px;
}

.solutionTitle {
  color: #3e3e3e;
  font: 600 16px/150% Plain, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: 16px;
}

.solutionDescription {
  color: #3e3e3e;
  font: 375 14px/21px Plain, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: 8px;
}

@media (max-width: 991px) {
  .solutionCard {
    max-width: 100%;
    margin-top: 20px;
  }
}
