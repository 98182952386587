.alertWrapper {
    border-radius: 8px;
    background-color: rgba(255, 243, 224, 1);
    display: flex;
    align-items: flex-start;
    gap: 8px;
    color: rgba(38, 50, 56, 1);
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 16px;
    font: 600 16px/1 Plain, sans-serif;
    border: 1px solid rgba(255, 152, 0, 1);
    width: 890px;
    margin-bottom: 10px;
}

.alertIcon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
}

.contentContainer {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    flex-basis: 0%;
}

.messageWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
}

.alertMessage {
    flex: 1;
    width: 100%;
    padding-top: 5px;
    gap: 8px;
}

@media (max-width: 991px) {

    .contentContainer,
    .messageWrapper,
    .alertMessage {
        max-width: 100%;
    }
}