#sliderContainer {
    position: relative;
    width: 100%;
    height: 70px;
}

#slider {
    position: absolute;
    left: 0;
    padding: 0px;
    border-radius: 50px;
    height: 6px;
}

#divRange {
    display: flex;
    margin: 0px 15px;
    justify-content: space-between;
    font-size: 12px;
}

.filterRangeLabels {
    background-color: #06ac38;
    padding: 2px 5px;
    border-radius: 10px;
    color: white;
}

.css-1diafny-MuiSlider-root{
    color: black !important;
    width: calc(100% - 40px) !important;
    margin: 0px 20px !important;
}

.css-frv3qg-MuiSlider-thumb {
    width: 15px !important;
    height: 15px !important;
}