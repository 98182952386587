.customerSnapshots__container{
	background-color: #e5f2e9;
	padding: 0.5em;
	height: 100vh;
	border-radius: 5px;
	text-align: left;
	font-family: "Plain-Regular";
	padding-top: 0.5em;
	width: auto;
	margin-bottom: 20px;
	width: 99%;
	padding-left: 10px;
	margin-top: 10px;
	padding-bottom: 10px;
}
.container__sectionCustomerSnapshots {
  display: flex;
  flex-wrap: wrap;
}
.container__profileCustomerSnapshots {
  width: 100%;
}
.profile__CustomerSnapshots  {
  flex: 1 1;
  background-color: white;
  padding: 8px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 0.3rem;
  border-radius: 2px;
	/* height: 20vh; */
}

 @keyframes animateBorderSilver {
  from {
    border-image: linear-gradient(to bottom, transparent 50%, black 50%, black 95%, transparent 100%) 1 stretch;
  }
  to {
     border-image: linear-gradient(to bottom, transparent 0%, black 0%, black 50%, transparent 50%) 1 stretch;
  }
}
.silver:before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background: black;
  opacity: 0.5;
  z-index: -1;
}
.silver {
  margin-top: 5px;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(192, 192, 192, 1) 0%,  
    rgba(160, 160, 160, 1) 20%,  
    rgba(192, 192, 192, 1) 39%, 
    rgb(224, 224, 224) 50%,      
    rgba(192, 192, 192, 1) 60%, 
    rgba(160, 160, 160, 1) 80%,  
    rgba(192, 192, 192, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite ease;
  background-size: 200%;
  background-position: right;
}
.container__infoCustomerSnapshots {
  display: flex;
  padding-right: 12px 
}
.container__imgPagerUser {
  position: relative;
  height: 64px;
  width: 66px;
  background-color: #117170;
  border-radius: 50%;
  border: 6px solid white;
  overflow: hidden;
  margin: 2px 0px;
  box-shadow: 0px 0px 5px -1px black;
  margin-top: 6x;
}
.imgPagerUser__customerSnapshots {
  height: 56px;
  right: -1px;
  position: absolute;
}
.header__infoProfileCustomerSnapshots {
	width: 65%;
  	margin-left: 17px;
}
.title__headerCustomerSnapshots {
	font-weight: 600;
  margin-top: 10px;
	font-size: 15px;
}
.container__accountHigh {
	/* background: #f2d2d2de; */
	padding: 10px;
	border-radius: 3px;
	margin-top: 7px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.list__accountHig {
	list-style: disc;
	padding-left: 16px ;
}
.separation__title {
	background-color: #06ac38;
	color: #fff;
	padding: 10px;
	font-weight: 600;
}
.container__sectionDataCustomerSnapshots {
	width: 99%;
	padding-left: 10px;
}
.data__sectionCustomerSnapshots {
	margin-top: 10px;
	margin-bottom: 10px;
}
.container__dataHeader {
	display: flex;
	justify-content: center;
}
.container__firstDivCustomerSnapshots {
	width: 75%;
}
.subtitle__headerCustomerSnapshots {
	font-size: 13px;
  color: #5a5858;
	display: flex;
}
.container__titleHeaderCustomerSnapshots {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 12px;
}
.link__headerCustomerSnapshots {
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
}
.type__headerCustomerSnapshots {
	background: #dfdcdc;
	padding: 2px;
	border-radius: 5px;
	margin-left: 15px;
	margin-bottom: 10px;
}
.title__status {
	/* color: #D92626; */
}
.list__accountHig  > li {
	margin-bottom: 0px;
}
.icon__TriangleExclamation {
	color: #D92626;
	font-size: 30px;
	margin-right: 32px;
	margin-left: 10px;
}
.container__cardAccountData {
  display: flex;
	flex-wrap: wrap;
}
.card__infoAccountData {
  height: fit-content;
  background-color: white;
  padding-left: 23px;
  padding-right: 12px;
  padding-bottom: 20px;
  padding-top: 10px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
}
.cardAccountData {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 196px;
  text-align: center;
  padding: 10px;
  margin-bottom: 5px;
  box-shadow: 14px -7px 57px -20px rgba(0,0,0,0.1), 0px 10px 15px -3px rgba(0,0,0,0.1);
  border-radius: 3px;
  background-color: white;
 }
 .title__cardAccountData {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.subtitle__cardAccountData {
  color: #171717;
  font-size: 18px;
  margin-top: 10px;
	font-weight: 600;
 }
 .container__headerTitleCard {
	margin-bottom: 10px;
 }
 .text__subtitleCardAccountData{
	font-size: 13px;
    color: #808181;
 }
 .icon__cardTriangleExclamation {
	color: #D92626;
	margin-right: 10px;
 }	
 .text__red {
	color: #D92626;
 }
 .omm__subtitle {
	background-color: #cf541b;
	color: #fff;
	margin-top: 12px;
	font-size: 13px;
	width: auto;
	border-radius: 5px;
	margin-bottom: 10px;
	width: 71px;
	margin-left: 51px;
 }
.strong__text {
	margin-right: 10px;
}
.card__accountHealth {
  background-color: white;
  padding-left: 23px;
  padding-right: 12px;
  padding-bottom: 20px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 1px;
  width: 26%;
	margin-left: 10px;
}
.info__accountHealth {
	display: flex;
	flex-wrap: wrap;
}
.title__accountHealth {
	font-size: 12px;
	font-weight: 700;
	width: 74px;

}
.subTitle__accountHealth {
	color: rgba(0, 0, 0, 0.80);
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 180%;
	letter-spacing: -0.132px;
	margin-left: 15px;
}
.icon__accountHealth {
	color: #D92626;
	margin-right: 5px;
}
.container__tooltipInfoCustomerSnapshot {
  margin-top: 7px;
  display: flex;
  column-gap: 8px;
  flex-wrap: wrap;
  row-gap: 11px;
	margin-bottom: 10px;
}
.text__view {
	color: #0052CC;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 180%;
	letter-spacing: -0.132px;
}
.section__secondAll {
	display: flex;
	flex-wrap: wrap;
	margin-top: 15px;
	padding-bottom: 70px
}
.section__first {
	width: 70%;
  padding-right: 12px;
  padding-bottom: 20px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 1px;
}
.container__productAdoption {
	background-color: white;
  padding-left: 23px;
  padding-right: 12px;
  padding-bottom: 20px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 1px;
	margin-bottom: 10px;
}
.container__dataNumber {
	background-color: white;
  padding-left: 23px;
  padding-right: 12px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 1px;
	margin-bottom: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
}
.section__second{
	background-color: white;
  padding-left: 23px;
  padding-right: 12px;
  padding-bottom: 20px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 1px;
  width: 29%;
	margin-left: 13px;
}
.title__headerSection {
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 24px */
	letter-spacing: -0.176px;
	color: #000;
	padding-top: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-wrap: wrap;
}
.card__timeline {
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 14px -7px 57px -20px rgba(0,0,0,.1), 0 10px 15px -3px rgba(0,0,0,.1);
	margin-bottom: 5px;
	padding: 16px;
	margin-top: 20px;
}
.card__timelineHeader {
	display: flex;
}
.timelineHeader {
	display: flex;
	margin-left: 10px;
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; 
	letter-spacing: -0.176px;
}
.timeline__subtitle {
	margin-left: 6px;
}
.timeline__day {
	color: #000;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; 
	letter-spacing: -0.132px;
	margin-top: 10px;
}
.timeline__sentiment {
	display: flex;
	height: 23px;
	align-items: center;
	gap: 6px;
	border-radius: 6px;
	background: var(--gray-200, #E2E8F0);
	margin-top: 10px;
	width: 163px;
	padding: 4px;
}
.timeline__sentimentTitle {
	color: #1A202C;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; 
}
.sentiment__negative {
	color: #C53030;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}
.timeline__description {
	color: #000;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.154px;
	margin-top: 10px;
}
.footer__timeline {
	display: flex;
	margin-top: 15px;
}
.timeline__view {
	margin-left: 10px;
	color:  #0052CC;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 180%; 
	letter-spacing: -0.132px;
}
.sentiment__positive {
	color: #2C7D54;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}
.sentiment__neutral {
	color: #0052CC;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}
.title__productAdoption {
	color: rgba(0, 0, 0, 0.80);
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 180%; /* 21.6px */
	letter-spacing: -0.132px;
}
.section__productAdoption {
	margin-top: 20px;
}
.reactive {
	color:#DD6B20
}
.manual {
	color: #D92626
}
.responsive {
	color: #00B5D8
}
.proactive {
	color: #319795
}
.preventative {
	color: #2C7D54
}
.dataNumber {
	width: 185px;
}
.containerInput__ia {
	display: flex;
}
.input__ia {
	border-radius: 8px;
	width: 90%;
	border: 1px solid #8A95A8;
	padding: 4px;
}
.button__ia {
	border-radius: 6px;
	border: 1px solid #8A95A8;
	background: #06AC38;
	color: #fff;
	width: 8%;
	padding: 2px;
	margin-left: 10px;
}
.icon__ia {
	color: #fff;
}
.gen__ai {
	background-color: #E2E8F0;
	border-radius: 6px;
	height: 25px;
	width: 80px;
	font-size: 13px;
	margin-left: 14px;
}
.conatiner__businessAiOps {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
}
.businessAiOps__firts {
	width: 49%;
	background-color: white;
	padding-left: 23px;
	padding-right: 12px;
	padding-bottom: 20px;
	box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
	border-radius: 1px;
}
.businessAiOps__second {
	width: 49%;
	background-color: white;
	padding-left: 23px;
	padding-right: 12px;
	padding-bottom: 20px;
	box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
	border-radius: 1px;
	margin-left: 19px;
}
.header__businessAiOps {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.title__businessAiOps {
	margin-bottom: 1px;
}
.subTitle__businessAiOps {
	padding-top: 1px;
}
.container__tablaeu{
	border-radius: 8px;
	background: #FFF;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
	padding: 5px;
}
.text__tablaeu {
	color:  #0052CC;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 180%; 
	letter-spacing: -0.132px;
}
.title__infoBusinessAiOps {
	color: rgba(0, 0, 0, 0.80);
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 180%;
	letter-spacing: -0.132px;
	margin-top: 20px;
	margin-bottom: 2px;
}
.description__infoBusinessAiOps {
	display: flex;
	flex-wrap: wrap;
}
.infoBusinessAiOps__subInfo {
	width: 250px;
}
.container__infoBusinessAiOps {
	margin-top: 25px;
}
.infoAccountPersonnel__subInfo {
	width: 120px;
	font-weight: 600;
	font-size: 14px;
}
.container__itemSelectedButton {
	margin-top: 20px;
}
.segmented-control {
  display: flex;
	width: 100%;
}
.segmented-control-button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  background-color: transparent;
  cursor: pointer;
	border: none;
	border-bottom: 2px solid #8a9096;
}
.segmented-control-button.selected {
  border-bottom: 2px solid 	#003F9C; 
}
.pricing {
	margin-bottom: 15px;
	margin-top: 15px;
	font-size: 16px;
}
.automation {
	margin-top: 15px;
}
.renewal {
	margin-left: 10px;
	margin-right: 5px;
}
.executiveSummary {
	width: 90%;
	margin-left: 10px;
}
.text__successPlanSummary {
	margin-top: 10px;
}
.container__selectSubdomain {
  height: fit-content;
  background-color: white;
  padding-left: 23px;
  padding-right: 12px;
  padding-bottom: 20px;
  padding-top: 10px;
  box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
	margin-bottom: 10px;
}
.dropdown-toggleButtonSubdomain {
  padding: 8px 12px;
  background-color: #fff;
  cursor: pointer;
	border: 1px solid #8A95A8;;
  border-radius: 5px;
	width: 100%;
	text-align: left;
}
.dropdown-toggleButtonSubdomain::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin-top: 11px;
}
.dropdown__subdomainHomeSnapshots {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1;
  height: 200px;
  overflow-y: scroll;
  width: 100%;
}
.dropdown__subdomainHomeSnapshots li {
  padding: 8px 12px;
}
.container__pageyCustomer {
	display: flex;
	justify-content: center;
}

@media screen and (max-width: 1000px) { 
	.container__profileCustomerSnapshots {
    width: 100%;
  }
	.card__accountHealth {
		width: 100%;
		margin-left: 0px;
		margin-top: 10px;
	}
	.section__first , .section__second, .businessAiOps__firts, .businessAiOps__second{
		width: 100%;
	}
	.businessAiOps__second {
		margin-top: 10px;
		margin-left: 0px;
	}
	.section__second {
		margin-left: 0px;
	}
}
