#EmojiPicker {
  position: relative;
  width: 100%;
}

#EmojiPicker-dropdown-div {
  position: relative;
}

#EmojiPicker-dropdown-content {
  border: 2px solid;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
  position: absolute;
  z-index: 1;
}

#EmojiPicker-chevron {
  position: absolute;
  right: 15px;
  top: calc(50% - 10px);
  color: #a5a5a5;
  cursor: pointer;
}

#EmojiPicker-chevron input {
  padding-right: 40px;
}

#EmojiPicker-dropdown-content-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  margin-bottom: 5px;
}

#EmojiPicker-dropdown-content-icons {
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

#EmojiPicker-dropdown-content button {
  border: 0;
  background-color: transparent;
  outline: none;
}