.pagination__container {
  display: flex;
  align-items: center;
}
.btn__pagination{
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
}
.number__page {
  align-items: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 3px;
  padding: 8px;
  width: 32px;
}
.number__page:hover, .btn__pagination:hover{
  color: #048a24;
}
.containerNumber__page {
  margin: 0px 9px;
  display: flex;
  width: auto;
}
.current__page {
  align-items: center;
  background-color: #048a24;
  border-radius: 50%;
  border: none;
  color: #ffff;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
}
.current__page:hover {
  color: white;
}
button.btn__pagination:disabled {
  cursor: not-allowed;
  color: #C5C5C5;
  background-color: transparent !important;
}