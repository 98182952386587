@import "../../assets/fonts/fontsInterface.css";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(17 24 39 / 0.5);
  z-index: 1000;
  font-family: 'Plain-Regular';
}

.modal-main {
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  background: white;
  width: 100%;
  top: 50%;
  overflow: auto;
  max-height: 90vh;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.FeedbackModal {
  max-width: 700px!important;
}
.display-block {
  display: block;
}

.display-none {
  display: none;
}

#modal-button-close-div {
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  align-items: flex-start;
  border-bottom: 1px solid #EFEFEF;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #343434;
}

.modal-button-close {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  border-radius: .375rem;
  background-color: transparent;
  padding: 0.375rem;
  font-size: 22px;
  border: none;
  color: #343434;
  
}
.modal-button-close:hover {
  color: rgb(17 24 39 / 1);
  background-color: rgb(229 231 235 / 1);
}

#modal-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 125%;
  --tw-text-opacity: 1;
  /* color: rgb(17 24 39 / var(--tw-text-opacity)); */
  width: 85%;
}

#modal-container {
  width: auto !important;
  max-width: 100%;
  flex: 1 1 0%;
  overflow: auto;
  padding: 24px 32px;
}

#modal-action-buttons{
  padding: 1.5rem;
  align-items: center;
  display: flex;
}
