.Layout {
  display: flex;
  width: 100%;
  display: flex;
}

.container {
  display: flex;
  width: 100%;
  background-color: #f7f7f7;
}

.input-error {
  color: #e23e32;
  margin-top: 5px;
}

.page-content {
  width: 100%s;
  padding: 30px;
  flex: 1 1;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

#Administration-container {
  padding: 30px 40px;
  height: calc(100vh - 60px);
  overflow-x: hidden;
}

#Administration-title {
  font-size: 40px;
  margin: 0;
  margin-left: 15px;
}

#Administration-title-container {
  border-bottom: 1px solid #4b4f4f;
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
}

#Administration-button-container {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

#Administration-table-container {
  height: calc(100vh - 240px);
  margin-top: 10px;
}