.summary-table table {
  width: 100%;
  border-collapse: collapse;
}

.summary-table th, .summary-table td {
  padding: 12px;
  text-align: left;
  border-top: 1px solid  rgba(233, 233, 233, 1);
  font-size: 16px;
  font-weight: 375;
  line-height: 24px;
}

.summary-table th {
  color: rgba(100, 100, 100, 1);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: none;
}

.summary-table tbody tr:nth-child(odd) {
  background-color:rgba(250, 250, 250, 1);
}