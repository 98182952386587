.side-control{
  background-color: #e7ddcf;
}
.control-container{
  padding: 20px;
}


.object-buttons {
  display: flex;
}

.object-select {
  width: 100%;
  max-width: 200px;
  border-radius: 4px;
  padding: 3px;
}

.properties-container {
  background-color: white;
  flex-direction: column;
  padding: 10px 20px;
  font-size: 13px;
  margin-bottom: 10px;
}

.properties-container div{
  margin: 8px 0;
}

.properties-container label{
  font-weight: bold;
}

.delete {
  background-color: #f82431;
  color: white;
  border:none;
  padding: 10px 30px;
  border-radius: 4px;
}

.object-buttons button{
  padding: 10px 30px;
  color: #fa640a;
  background-color: #FFF;
  border:none;
  border-radius: 4px;
}


.navigation-link {
  color: black;
}
