.premium-request__modal-card-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.premium-request__modal-card-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
}

.premium-support-request__modal-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.premium-support-request__modal-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.premium-support-request__modal-input,
.premium-support-request__modal-textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.premium-support-request__modal-input {
  height: 45px;
}

.premium-support-request__modal-textarea {
  min-height: 100px;
  resize: vertical;
}

.premium-support-request__modal-input:focus,
.premium-support-request__modal-textarea:focus {
  outline: none;
  border-color: #048a24;
}

.premium-support-request__modal-col1,
.premium-support-request__modal-col2 {
  display: grid;
  gap: 16px;
}

.premium-support-request__modal-col1 {
  grid-template-columns: 1fr;
}

.premium-support-request__modal-col2 {
  grid-template-columns: 1fr 1fr;
}

.premium-support-request__modal-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.premium-support-request__modal-col1_dates {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Date picker styles */
.datepicker-portal {
  background-color: #048a24;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  font-size: 14px;
  background-color: #048a24;
  color: white;
}

@media (max-width: 768px) {
  .premium-support-request__modal-col2 {
    grid-template-columns: 1fr;
  }
}
