.content__selectClient {
	display: flex;
	justify-content: center;
	width: 100%;
	height: 90vh;
	align-content: center;
	align-items: center;
}
.card__selectClient {
	width: 400px;
	background-color: white;
	padding: 20px;
	box-shadow: rgba(56, 65, 74, 0.03) 0px 0.75rem 6rem;
	border-radius: 0.3rem;
	border-radius: 2px;
	background-size: contain;
	background-repeat: no-repeat;
}
.container__selectClientPageyUser {
  position: relative;
  height: 170px;
  width: 170px;
  background-color: #117170;
  border-radius: 50%;
  border: 10px solid white;
  overflow: hidden;
  margin: 20px 0px;
  box-shadow: 0px 0px 5px -1px black;
}
.img__selectClientPageyUser {
  height: 180px;
  right: -14px;
  position: absolute;
}
.container__imgPageyUse{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
}
.text__welcome {
	font-weight: bold;
	width: 100%;
	text-align: center;
	color: #ffff;
}
.container__selectClient { 
	position: relative;
	display: inline-block;
	width: 100%;
}
.dropdown-toggleButton {
  padding: 8px 12px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #002b4f;
  border-radius: 5px;
	width: 100%;
	text-align: left;
}
.dropdown-toggleButton::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin-top: 11px;
}
.search__clientsFilter {
  padding: 5px;
  margin: 10px;
	width: 93%;
}
.submit__buttonFilter {
  position: relative;
  display: inline-block;
  padding: 5px 7px;
  text-align: center;
  font-size: 17px;
  text-decoration: none;
  color: #002b4f;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 1px solid #002b4f;
  border-radius: 5px;
  box-shadow: inset 0 0 0 0 #002b4f;
	width: 40%;
	margin-top: 40px;
}
.submit__buttonFilter:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #002b4f;
}
.submit__buttonFilter:active {
  transform: scale(0.9);
}
.disabled__dtnSaveFilter {
  opacity: 0.5;
}
.container__btnSubmit {
	text-align: center;
}
.page__containerSelectClient{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.footer__selectClient {
  margin-top: auto;
}
.container__subdomain {
  margin-top: 20px;
}
.loading__submit {
  --clr: rgb(0, 113, 128);
  --gap: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
  margin-top: 40px;
 }
 .loading__submit span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--clr);
  opacity: 0;
 }
 
 .loading__submit span:nth-child(1) {
  animation: fade 1s ease-in-out infinite;
 }
 
 .loading__submit span:nth-child(2) {
  animation: fade 1s ease-in-out 0.33s infinite;
 }
 
 .loading__submit span:nth-child(3) {
  animation: fade 1s ease-in-out 0.66s infinite;
 }
 .dropdown-menuClient {
  display: block;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1;
  height: 200px;
  overflow-y: scroll;
  width: 361px;
 }
 .dropdown-menuClient li {
  padding: 8px 12px;
}
.container__errorHandlerSubdomain {
  margin-top: 5px;
  margin-bottom: 15px;
}