@keyframes shimmer {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.SOD-contain{
  height: calc(100vh - 138px);
  margin: 20px 25px;
  width: 90%;
}

.SOD-title {
  display: flex;
  height: 70px; /* Height of your text line */
  width: 30%; /* Width of your text container */
  background: #eee; /* Light grey background */
  background-image: linear-gradient(to right, #eee 0%, #f5f5f5 20%, #eee 40%, #eee 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: shimmer 1.5s linear infinite;
  border-radius: 4px;
  margin-top: 5px;
}

@keyframes shimmerInput {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.skeleton-input-sod {
  height: 40px; /* Height of your input field */
  width: 375px; /* Width of your input container */
  background: #eee; /* Light grey background */
  background-image: linear-gradient(to right, #f0f0f0 8%, #ddd 18%, #f0f0f0 33%);
  background-size: 800px 100px;
  animation: shimmerInput 1.5s linear infinite forwards;
  border-radius: 4px; /* Mimic the border-radius of typical inputs */
  margin: 5px 0; /* Optional, for spacing */
  margin-left: 10px;
}

.skeleton-table {
  width: 100%;
  background-color: white;
  border: 1px solid #647487;
  border-radius: 5px;
  overflow-x: auto;
}

.skeleton-row {
  display: flex;
  margin-bottom: 10px;
  background-color: white;
}

.skeleton-row-title {
  display: flex;
  margin-bottom: 10px;
  background-color: #edf2f7;
  color: #647487;
  display: flex;
  height: 40px;
}

.skeleton-cell-text {
  display: inline-block;
  height: 16px; /* Height of your text line */
  width: 100%; /* Width of your text container */
  background: #eee; /* Light grey background */
  background-image: linear-gradient(to right, #eee 0%, #f5f5f5 20%, #eee 40%, #eee 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: shimmer 1.5s linear infinite;
  border-radius: 4px;
}

.skeleton-cell {
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  display: flex;
  flex-grow: 1;
  height: 20px;
  border-radius: 4px;
  margin-right: 5px;
  align-items: center;
  border-top: 1px solid #e0e1e2;
  display: flex;
  height: 50px;
  justify-content: flex-start;
}

.skeleton-cell.header {
  font-weight: bold;
  height: 25px;
}

/* Last cell of a row should not have right margin */
.skeleton-cell:last-child {
  margin-right: 0;
}


@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.skeleton-text-sod {
  display: inline-block;
  height: 16px; /* Height of your text line */
  width: 170px; /* Width of your text container */
  background: #eee; /* Light grey background */
  background-image: linear-gradient(to right, #eee 0%, #f5f5f5 20%, #eee 40%, #eee 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: shimmer 1.5s linear infinite;
  border-radius: 4px;
  margin: 13px;
}

.tab-list-sod {
  border-bottom: 1px solid #ccc;
  display: flex;
  margin: 0;
  padding-left: 4em;
}