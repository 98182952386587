.center-corner {
  background-image: radial-gradient(#ffffff33 40%, #06ac38 60%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0% 0%;
  transition: background-size 0.5s, color 0.5s;
}

.center-corner:active {
  background-size: 100% 100%;
  color: #fff;
}
.container__cart {
  width: 60px;
  height: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 20px;
}
.total__cart {
  background: #ec4733;
  border: 1px solid #fff;
  border-radius: 5rem;
  font-size: 12px;
  width: 1.2rem;
  height: 1.2rem;
  padding: 0;
  line-height: 1rem;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  top: 0px;
  right: 8px;
  color: white;
  z-index: 2;
}
.icon__cart {
  position: relative;
}
.detail__cart {
  position: absolute;
  top: 55px;
  right: 13px;
  background-color: #ffff;
  border-radius: 5px;
  width: 25%;
  height: 452px;
  z-index: 10;
  padding: 10px;
  border: 1px solid #CCC;
}
.cardHorizontal__cart {
  display: flex;
  width: 100%;
  height: auto;
  background-color: #ffff;
  border-radius: 8px;
  box-shadow: 0 0 12px #d7d7d7;
  flex-wrap: wrap;
  padding: 7px;
  margin-bottom: 6px;
}
.img__cardCart {
  max-width: 100%;
  height: 70px;
  max-height: 77px;
}
.header__cardCart {
  flex: 0 0 30%; 
}
.body__cardCart {
  text-align: start;
  padding: 0 20px; 
  width: 70%;
}
.name__cardCart {
  color: #002b4f;
  font-size: 15px;
  font-weight: bold;
}
.card__nameAndTypeCart {
  display: flex;
  font-size: 12px;
  color: #3f3f3f;
}
.card__typeCart {
  border-left: 1px solid #c6c0c0;
  margin-left: 10px;
  padding-left: 10px;
}
.footer__hourCart {
  color: #3f3f3f;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.container__cardCart {
  max-height: 320px;
  overflow-y: scroll;
  height: 320px;
}
.total__hours {
  color: #3f3f3f;
  font-weight: bold;
  margin-top: 10px;
}
.btn__submitCart {
  border-radius: 7px;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #002b4f;
  box-shadow: 0 0 0 1px #002b4f, 0 0 0 0px #fff;
  cursor: pointer;
  background-color: transparent;
  margin-top: 13px;
}
.cart__isEmpty {
  color: #3f3f3f;
  font-weight: bold;
  position: relative;
  text-align: center;
  bottom: 50%;
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.img__pageyUserCart {
  height: 100px;
  right: -11px;
  position: absolute;
}
.container__imgUserCart {
  position: relative;
  height: 100px;
  width: 100px;
  background-color: #117170;
  border-radius: 50%;
  border: 10px solid white;
  overflow: hidden;
  box-shadow: 0px 0px 5px -1px black;
  margin-bottom: 10px;
  left: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 67%;
}
.total__cartService[type="number"]::-webkit-inner-spin-button,
.total__cartService[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.total__cartService[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.cart__addServices {
  display: flex;
}
.total__cartService{
  padding: 0;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 36px;
  height: 24px;
  text-align: center;
  color: #002b4f
}
.container__btnAdd {
  border: 1px solid #002b4f;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: relative;
  top: 3px;
}
.btnAdd__text {
  position: absolute;
  top: -4px;
  left: 5px;
}
.less__btn {
  position: absolute;
  top: -4px;
  left: 6px;
}
.hour__cost, .category__nameCar {
  color: #3f3f3f;
  /* padding-left: 10px; */
}
.clean__cart {
  color: #002b4f;
  font-size: 12px;
  font-weight: bold;
  text-align: end;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 15px;
}
.cleanCart__text {
  margin-right: 13px;
}
.footer__detailCart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.show__moreServices{
  color: #002b4f;
  text-align: end;
  margin-top: 15px;
  margin-bottom: 10px;
}
.cart__isEmptySend {
  color: #3f3f3f;
  font-weight: bold;
  text-align: center;
  bottom: 50%;
}
.detail__cartSucces {
  position: absolute;
  top: 55px;
  right: 13px;
  background-color: #ffff;
  border-radius: 5px;
  width: 25%;
  height: 190px;
  z-index: 10;
  padding: 10px;
  border: 1px solid #CCC;
}
.not__cart {
  width: 100%;
}
.btn-primary:disabled {
  background-color: #bbb!important;
}
.premiumInnovationDemoText {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #FFF;
  flex-wrap: nowrap;
  display: flex;
  color: #343434;
}

.premiumInnovationDemoButton {
  border: 1px solid #FFFFFF;
  padding: 0px 16px;
  border-radius: 8px;
  color: #FFFFFF;
  background-color: transparent;
  height: 40px;
  flex-wrap: nowrap;
  display: flex;
  width: 116px;
  justify-content: center;
  align-items: center;
}
.tertiaryButton {
  transition: all 0.3s ease;
  border: 1px solid transparent!important;
  padding: 0;
}
.highlightButton{
  background-color: #048A24;
  color: white;
  transition: all 0.3s ease;
  padding: 8px;
  border-radius: 8px;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 14px;
}
.tertiaryButton:hover {
  border: 1px solid #FFFFFF!important;
  border-radius: 8px!important;
  color: #FFFFFF!important;
  background-color: transparent!important;
}

.highlightButton:hover{
  background-color: #005A24;
  /* color: white; */
}
.premiumInnovationDemoButton:hover {
  background-color: #FFFFFF;
  color: rgb(0, 90, 36);
}

.premiumInnovationDemoContainerTop{
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.premiumInnovationDemoContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-left: 8px;
}
@keyframes heartbeatAnimation {
  0%, 20% {
    transform: scale(1);
  }
  2% {
    transform: scale(1.09);
  }
  4% {
    transform: scale(1);
  }
  6% {
    transform: scale(1.07);
  }
  8% {
    transform: scale(1);
  }
  20%, 100% {
    transform: scale(1);
  }
}

.heartbeatAnimation {
  animation: heartbeatAnimation 8.5s ease-in-out 3;
  transform-origin: center center;
}