.healthCheckDataViewer__container {
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  padding: 40px;
}
.containerForm {
  height: calc(100% - 92px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerFormInner {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 6px 0px #00000030;
    border-radius: 6px;
    row-gap: 15px;
}

.buttonHCDV {
    font: inherit;
    color: inherit;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
    height: 40px;
    border-radius: 8px;
    line-height: 1;
    border: 2px solid var(--c-border-primary);
    color: var(--c-text-action);
    font-size: 1rem;
    transition: 0.15s ease;
    width: 100%;
  }

  .buttonHCDV.primary {
    background-color: #08AC38;
    color:#e9ecf4;
  }

  .MuiInputBase-input {
    border: 2px solid #EDF2F7 !important;
    background-color: white !important;
    color: #06ac38 !important;
    border-radius: 5px;
    width: 100%;
  }
  .MuiTextField-root {
    width: 100%;
  }