.dropableRow {
    margin: 10px 20px;
    font-size: 24px;
    border: 2px solid #005a24;
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    cursor: grab;
}

.dropableRow[drop-active=true] {
    box-shadow: inset 0px 0px 0px 2px #00C;
}

.dropableRowHover {
    margin: 10px 20px;
    font-size: 24px;
    border: 2px dotted #707070;
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    cursor: grab;
}