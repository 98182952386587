#Chevron-container {}

#Chevron-container-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

#Chevron-title {
    font-size: 18px;
    font-weight: bold;
}

#Chevron-chevron {
    font-size: 23px;
    cursor: pointer;
}

.chevron-spacing {
    margin: 15px 0px;
}