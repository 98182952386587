.banner {
    width: 100%;
    padding: 10px 0;
    color: #000;
    text-align: start;
    font-weight: bold;
  }
  
  .banner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }
  
  .maintenance-banner-icon {
    margin-right: 20px;
    height: 1.5em;
  }
  
  .banner-info {
    background-color: #e3f2fd;
    color: #0d47a1;
  }
  
  .banner-warning {
    background-color: #fff3e0;
    color: #e65100;
  }
  
  .banner-error {
    background-color: #ffebee;
    color: #b71c1c;
    border: 1px solid #F44336;
  }
  
  .banner-success {
    background-color: #e8f5e9;
    color: #1b5e20;
  }

