#PS-Status-title-container {
  display: flex;
  padding-bottom: 10px;
  width: 100%;
  justify-content: space-between;
}

#table-container{
}

#table-container-revenue{
  height: calc(100% - 65px);
  overflow-y: auto;
}

.tab-list, .sub-tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 4em;
  display: flex;
  margin: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: .7rem 2rem;
  font-weight: bold;
  color: #8F9BAA;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
.pills-list{
  display: flex;
  margin: 0 15px;
  border-radius: 10px;
  background-color: #DEE2E9;
}

.pill-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: .75rem 1rem;
  font-weight: bold;
  color: #8F9BAA;
  cursor: pointer;
  margin: 3px 3px
}

.pill-list-item-active {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: .75rem 1rem;
  font-weight: bold;
  color: #505C68;
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  margin: 3px 3px
}

.tab-list-item-active {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: .7rem 2rem;
  font-weight: bold;
  color: #016080;
  cursor: pointer;
  border-bottom: 2px solid #016080;
}
.sub-tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  margin: .5rem .75rem;
  padding: .2rem .5rem;
  font-weight: bold;
  color: #8F9BAA;
  cursor: pointer;
  border: 1px solid transparent;
}

.sub-tab-list-item-active {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  margin: .5rem .75rem;
  padding: .2rem .5rem;
  font-weight: bold;
  color: #016080;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #8F9BAA;
}

.button-export {
  color: #FFF;
  padding: 1em 1.5em;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 500;
  background-color: #016080;
}

.button-export:hover{
  color: #016080;
  background-color: #A5C6DF;
}

.button-modal {
  color: #FFF;
  padding: .5em 1em;
  border-radius: 10px;
  border: none;
  margin-left: 1em;
  text-decoration: none;
  font-weight: 500;
  background-color: #016080;
}

#PS-Status-container {
  margin: 20px 25px;
  height: calc(100vh - 138px);
  width: 95vw;
}

.PS-status-filter-container {
  display: flex;
  justify-content: end;
}

.filter-panel-statusApp {
  margin: 10px 0px;
  display: flex;
  width: min-content;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  justify-content: end;
}

.modal.left .modal-dialog,
 .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
       -o-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal-body {
  padding: 15px 15px 80px;
  text-align: left;
  font-size: 12px;
}

/*Left*/
.modal.left .modal-dialog{
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
       -o-transition: opacity 0.3s linear, left 0.3s ease-out;
          transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.in .modal-dialog{
  left: 0;
}

.modal.left.in.open{
  display: block;
}
      
/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #EEEEEE;
  background-color: #FAFAFA;
}

.close {
  border: none;
}

#HC-Incidents-container-left {
  width: 20vw;
  height: 100%;
  border: 3px solid #06ac38;
  border-radius: 10px;
}

#PS-Status-container-right-groupBars {
  width: 100%;
  height: 100%;
}

.accordion {
  border: solid 2px #f5f5f5;
  transition: all 0.3s ease-in-out;
  width: 600px; 
}

.accordion__title {
  list-style-type: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  color: #555555;
  padding: 0.875rem 2.5rem 0.875rem 0.875rem;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M23.024 11.263l-7.024 7.023-7.022-7.023-3.091 3.090 8.569 8.569c0.413 0.413 0.961 0.64 1.545 0.64s1.133-0.228 1.545-0.64l8.569-8.569-3.091-3.090z' fill='%23555555'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem top 0.625rem;
  background-size: 1.5rem;
}


.accordion__content  p {
  padding: 0 1rem 1rem 1rem;
  text-align: left;
  background-color: #F9F9FB;
  white-space: pre-wrap;
  font-size: 12px;
}

.filter-pills span{
  font-size: 10px;
  padding: 5px;
  background-color: #06ac38;
  border-radius: 14px;
  color: white;
  margin: 2px;
  white-space: pre;
}

#PSStatus-FAQ {
  display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 13px;
    margin-right: 20px;
}

#PSStatus-FAQIcon {
  cursor: pointer;
  color: rgb(148, 178, 165);
  font-size: 16px;
  margin-left: 10px;
}