.migrations-container {
  margin: .5em;
  padding: 1.5em;
  height: calc(100% - 8em);
  border-radius: 5px;
 
}

.migration-item {
  display: flex;
  flex-direction: column;
  max-width: 340px;
  max-height: 190px;
  text-align: center;
  padding: 44px 62px 25px 62px;
  justify-content: center;
  background-color: #94b2a5;
  color: #e7ddcf;
  cursor: pointer;
}
.migration-item:hover {

  background-color: #4b4f4f;
  color: #e7ddcf;

}
.availableMigrations-container {
  display: flex;
  display: flex;
  justify-content: space-around;
  margin-top: 157px;
}

.Layout {
  display: flex;
  width: 100%;
  display: flex;
}

.container {
  display: flex;
  width: 100%;
  background-color: #f7f7f7;
}