
.Icon-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Icon-container-Inner {
    position: static;
    overflow: hidden;
}

.Icon-icon {
    width: 50%;
    height: 50%;
}

.Icon-container-sandbox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
    overflow: hidden;
    border-radius: 50%;
    color: white;
    border: 3px solid #4b4f4f;
    position: absolute;
    top: 0;
    left: 0;
}

.Icon-container-sandbox:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 50px;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    transform: skewX(-30deg);
    animation-name: slide;
    animation-duration: 7s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-direction: forwards;
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0.13) 0%,
            rgba(255, 255, 255, 0.13) 77%,
            rgba(255, 255, 255, 0.753) 77%,
            rgba(255, 255, 255, 0.0) 0%);
}

@keyframes slide {
    0% {
        left: -10%;
        top: 0;
    }

    10% {
        left: 110%;
        top: 0;
    }

    100% {
        left: 110%;
        top: 0;
    }
}

