.migration-breadcrumb {
  text-align: left;
  padding: 20px;
  font-weight: bold;
}
.migration-wizard-container{
  padding: 20px 150px 50px 50px;
}

.migration-step-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #A9A8A8;
}

.migration-step-name {
}

.migration-step-data {
  text-align: left;
}

.label {
  font-weight: 600;
  display: block;
}

.input {
  margin: 10px 0;
  text-align: left;
}

.small{
 width: 345px;
}

.form-group {
  margin: 30px 0;
  margin-right: 10px;
}

.form-group-input {
  display: flex;
  justify-content: space-between;
  width: 65%;
}

.input-radio-container {
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}


.form-radio-container {
  display: flex;
  margin-right: 10px;
}

.form-radio-container label{
  width: auto;
}


.migration-wizard-button {
  margin-top: 30px;
  width: 220px;
  color: #fff;
  background-color: #005A24;
  padding: 14px;
  border-radius: 5px;
  border: none;
}

.migration-wizard-button.secondary{
  color: #005A24;
  background-color: #fff;
}

.button-group {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.button-group button{
  margin: 20px 0;
}

.form-group-button{
  display: flex;
  justify-content: space-around;
}

.terminal-base {
  min-height: 300px;
}

.input-select {
  background-color: white;
  width: 345px;
  color: black;
  padding: 10px;
  margin: 10px 0;
}

.numberCircle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 8px;
  margin-right: 10px;

  background: #fff;
  text-align: center;
  border: 2px solid #666;
  color: #666;

  font: 20px Arial, sans-serif;
}

.numberCircle.active {
  border: 2px solid #005A24;
  color: #005A24;
}

.folder-icon, .file-icon, .path-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.folder-content, .file-content, .folder-path-content {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.migration-step {
  padding: 15px 70px 15px 70px;
  display: flex;
  align-items: center;
}

.terminal-log-container {
  background-color: #000;
  color:white;
  height: 300px;
  padding: 10px;
  width: 1000px;
}

.loadings:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}}
