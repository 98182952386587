.dashboard-container {
  border-radius: 16px;
  height: calc(100vh - 60px);
  padding: 16px;
    /* padding: 1rem;
    width: calc(-50px + 100vw);
    overflow: auto; */
  }
  
  .dashboard-content {
    justify-content: space-between;
    min-width: 0;
    flex: 1 1 0%;
  }
  
  .dashboard-header {
    background-color: white;
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .dashboard-title {
    font-size: 26px;
    font-weight: 600;
    line-height: 24px;
    color: #117170;
    margin-right: 12px;
  }
  
  .dashboard-content {
    /* Add any specific styles for the dashboard content */
  }
  
  .dashboard-header {
    /* Styles for the dashboard header */
  }
  
  .dashboard-title {
    /* Styles for the main dashboard title */
  }
  
  .super-admin-view {
    background-color: white;
    padding: 6rem 1.5rem;
    height: calc(90vh - 60px);
  }
  
  .super-admin-container {
    max-width: 42rem;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  
  .super-admin-title {
    font-size: 2.25rem;
    font-weight: bold;
    letter-spacing: -0.025em;
    color: #111827;
  }
  
  .super-admin-description {
    margin-top: 1.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #4B5563;
  }
  
  .form-label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #111827;
  }
  
  .form-select {
    margin-top: 0.5rem;
    display: block;
    width: 100%;
    border-radius: 0.375rem;
    border: none;
    padding: 0.375rem 0.75rem;
    color: #111827;
    height: 44px;
    box-shadow: 0 0 0 1px #D1D5DB inset;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  
  .warning-box {
    border-radius: 0.375rem;
    background-color: #FFFBEB;
    padding: 0.25rem;
  }
  
  .warning-content {
    display: flex;
  }
  
  .warning-icon {
    flex-shrink: 0;
  }
  
  .warning-icon-svg {
    height: 1.25rem;
    width: 1.25rem;
    color: #F59E0B;
  }
  
  .warning-text {
    margin-left: 0.75rem;
    width: 100%;
  }
  
  .warning-title {
    font-size: 0.875rem;
    font-weight: 500;
    color: #92400E;
  }
  
  .warning-message {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #854D0E;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  
  .warning-button {
    border-radius: 8px;
    color: rgb(245, 158, 11);
    background-color: transparent;
    border: 1px solid rgb(245, 158, 11);
  }
  
  .user-name {
    margin-top: 0.5rem;
    font-size: 1.2rem;
    font-weight: 700;
    color: #111827;
    line-height: 2rem;
    text-overflow: ellipsis;
  }
  
  .user-info {
    margin-top: 0.25rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding: 1rem 0;
  }
  
  .user-info-item {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #6b7280;
    font-weight: 600;
  }
  
  .user-info-icon {
    margin-right: 0.375rem;
    height: 20px;
    width: 20px;
    color: #117170;
  }
  
  .dashboard-body {
    background-color: #ffffff;
  }
  
  .dashboard-section {
    border-top: 1px solid #E5E7EB;
    border-bottom: 1px solid #E5E7EB;
  }
  
  .section-header {
    border-top: 1px solid #E5E7EB;
    padding: 0.625rem;
    background: white;
  }
  
  .section-title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
    color: rgb(17, 113, 112);
  }
  
  .tabs-nav {
    display: flex;
    gap: 16px;
    padding: 0 1rem;
  }
  
  .tab {
    border-radius: 0.375rem;
    padding: 8px 12px;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  }
  
  .tab:hover {
    background-color: #e5e7eb;
    color: #111827;
  }
  
  .tab-active {
    background-color: #e5f2e9;
    color: #048a24;
  }
  
  .filter-section {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .search-container {
    position: relative;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
  }
  
  .search-shortcut {
    position: absolute;
    inset: 0px 0px auto auto;
    padding: 0.375rem 0.375rem 0.375rem 0;
  }
  
  .shortcut-key {
    display: inline-flex;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid #e5e7eb;
    padding: 0 0.25rem;
    font-family: sans-serif;
    font-size: 0.75rem;
    color: #048a24;
    background-color: #e5f2e9;
  }
  
  .filter-controls {
    display: flex;
  }
  
  .filter-button {
    color: #6b7280;
    font-size: 16px;
    background: transparent;
    border: none;
  }
  
  .filter-icon {
    margin-right: 0.375rem;
    height: 20px;
    width: 20px;
    color: #117170;
  }
  
  .clear-button {
    color: #6b7280;
    border: none;
    background: transparent;
    font-size: 16px;
  }
  
  .active-filters {
    border-top: 1px solid #e5e7eb;
    padding: 20px;
    max-width: 1120px;
    margin: auto;
  }
  
  .filter-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 16px;
  }
  
  .filter-fieldset {
    /* Add styles if needed */
  }
  
  .filter-legend {
    font-weight: 500;
    margin-bottom: 16px;
    font-size: 14px;
  }
  
  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .filter-option {
    display: flex;
    align-items: center;
  }
  
  .filter-checkbox {
    margin-right: 12px;
    accent-color: #6366f1;
  }
  
  .filter-label {
    flex: 1;
    color: #374151;
    font-size: 16px;
  }