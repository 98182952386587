@import "../../assets/fonts/fontsInterface.css";

.TableContainerNoAnimation {
  width: 100%;
  height: 100%;
  background-color: #EDF2F6;
  border-radius: 5px;
  overflow-x: auto;
  z-index: 2;
  border: 1px solid #647487;
}

.TableContainer {
  width: 100%;
  height: 100%;
  background-color: #EDF2F6;
  border-radius: 5px;
  overflow-x: auto;
  z-index: 2;
  animation: 1s ease-in 0s 1 slideOutFromLeft;
  border: 1px solid #647487;
}

.TableContainer.active {
  animation: 1s ease-in 0s 1 slideOutFromLeft;
}

.TableContainerOpen {
  width: 100%;
  height: 100%;
  background-color: #EDF2F6;
  border-radius: 5px;
  overflow-x: auto;
  transform: translateX(+50%);
  z-index: 2;
  animation: 1s ease-out 0s 1 slideInFromLeft;
  border: 1px solid #647487;
}

.tableDivMobile .TableContainer {
  width: 100%;
  height: 100%;
  background-color: #EDF2F6;
  border-radius: 5px;
  overflow-x: auto;
  z-index: 2;
  animation: 1s ease-in 0s 1 slideOutFromLeftMobile;
  border: 1px solid #647487;
}

.tableDivMobile .TableContainerOpen {
  width: 100%;
  height: 100%;
  background-color: #06ac38;
  border-radius: 5px;
  overflow-x: auto;
  transform: translateX(+99%);
  z-index: 2;
  animation: 1s ease-out 0s 1 slideInFromLeftMobile;
  border: 1px solid #647487;
}

#tableAndFilterContainer {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.buttonTable {
  background-color: transparent;
  border: none;
  outline: none;
  color: #647487;
  cursor: pointer;
}

.buttonTable:hover {
  outline: none;
  border: none;
}

.buttonTable:focus {
  outline: none;
  border: none;
}

.Titles {
  display: flex;
  height: 40px;
  background-color: #EDF2F7;
  color: #647487;
}

.TitleColumns {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  white-space: pre;
  /* resize: horizontal; */
  height: 38px;
  position: relative;
}

.TitleColumnsFrozen {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  /* resize: horizontal; */
  overflow: hidden;
  white-space: pre;
  height: 38px;
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;

}

.TitleColumnsFrozen span{
  background-color: #EDF2F6;
  border-left: 1px solid;
}

.TitleColumnsFrozenScrollableFirst {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  /* resize: horizontal; */
  overflow: hidden;
  height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 21em;
}

.TitleColumnsFrozenScrollable {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  /* resize: horizontal; */
  overflow: hidden;
  height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.TitleColumnsFrozenScrollable .divIconsTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #cfcece;
  cursor: pointer;
  right: 0;
}
.TitleColumnsFrozenScrollableFirst .divIconsTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #cfcece;
  cursor: pointer;
  right: 0;
}

.divIconsTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #cfcece;
  cursor: pointer;
}

.iconClass {
  color: white;
}

.FirstColumn {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  max-width: 100px;
}

.FirstColumnHide {
  width: 10px;
  display: flex;
  flex: 1 0 auto;
}

.GenericRow {
  display: flex;
  height: 50px;
  align-items: center;
  border-top: 1px solid #e0e1e2;
  justify-content: flex-start;
}

.GenericRowHover {
  display: flex;
  height: 50px;
  align-items: center;
  border-top: 1px solid #e0e1e2;
  justify-content: flex-start;
}

.GenericRowHover:hover {
  background-color: #e9ffee;
}

.GenericColumn {
  display: flex;
  align-items: center;
  color: #707070;
  flex: 1 0 auto;
  height: 100%;
}

.GenericColumnFrozen {
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;
  border-top-width: 1px;
  display: flex;
  align-items: center;
  color: #707070;
  flex: 1 0 auto;
}
.GenericColumnFrozen label{
  background-color: #fff;
  border-left: 1px solid;
}

.GenericColumnFrozenScollableFirst {
  display: flex;
  align-items: center;
  color: #707070;
  flex: 1 0 auto;
  height: 100%;
  margin-left: 21em;
}

.GenericColumnFrozenScollable {
  display: flex;
  align-items: center;
  color: #707070;
  flex: 1 0 auto;
  height: 100%;
}

.TextLabelRow {
  overflow: hidden;
  white-space: pre;
  padding-right: 6px;
  font-family: 'Plain-Thin';
  padding-left: 10px;
  font-weight: bold;
  /* width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; */
  width: 100%;
  height: 24px;
  display: inline-block;
}

.TextLabelRowTitle {
  display: flex;
  justify-content: inherit;
  font-size: 14px;
  font-family: 'Plain-Bold';
  padding-left: 10px;
  width: 100%;
}

.TableRowContainer {
  overflow: auto;
  height: calc(100% - 40px);
  width: fit-content;
  min-width: 100%;
  font-size: 14px;
  overflow-x: hidden;
  background-color: white;
  /* width: fit-content; */
}

.tableDivMobile {
  height: calc(100% - 2px);
  width: 100%;
}

.tableDiv {
  height: calc(100% - 2px);
  width: 100%;
}

.PaginationDiv {
  display: flex;
  margin-top: 10px;
  font-family: 'Plain-Thin';
}

.PaginationNumber {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 3px;
  text-align: center;
  border: 2px solid #647487;
  width: 60px;
}

.PaginationNumber::-webkit-outer-spin-button,
.PaginationNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.PaginationNumber[type='number'] {
  -moz-appearance: textfield;
}

.PaginationIconActive {
  margin-right: 5px;
  margin-left: 5px;
  color: #00000099;
  cursor: pointer;
}

.PaginationIconInActive {
  margin-right: 5px;
  margin-left: 5px;
  color: #e0e1e2;
}

/* CheckBox */

.nonBlock {
  display: inline;
}

.marginNonBlock {
  margin-left: 26px;
}

.inputNonBlock {
  height: 0px;
  margin-bottom: 10px;
  margin-right: 0px;
  width: 8px;
}

/* The container */

.containerTable {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 23px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.containerTable input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

/* On mouse-over, add a grey background color */

.containerTable:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.containerTable input:checked~.checkmark {
  background-color: #06ac38;
  border-radius: 3px;
  border: 0px solid;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */

.containerTable .checkmark:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Show the checkmark when checked */

.containerTable input:checked~.checkmark:after {
  display: block;
}


.tooltip {
  display: inline-block;
  position: relative;
  opacity: 1;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 15px;
  margin-left: 5px;
  font-size: 18px;
  width: max-content;
  top: 16px;
  position: absolute;
  z-index: 1;
  /* white-space: nowrap; */
  overflow: hidden;
  /* text-overflow: ellipsis; */
  max-width: 500px;
  display: -webkit-box;
  text-overflow: -o-ellipsis-lastline;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#pagination-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

#bottomContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

#FilterMenu {
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

#filterButton {
  padding: 6px 15px;
  border-radius: 5px;
  color: white;
  background-color: #94b2a5;
  font-size: 16px;
  outline: none;
  cursor: pointer;
}

#filterButton:hover {
  background-color: #94b2a5;
}

#filtersContainer {
  width: calc(100% - 50px);
  height: calc(100% - 52px);
  margin: 15px;
  border: 1px solid #dededf;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

#FilterMenuContainer {
  height: calc(100% - 39px);
  padding: 20px;
}

.hiddenRowDiv {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  margin-bottom: 12px;
}

.hiddenRowText {
  width: 100%;
  text-align: center;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(+50%);
  }
}

@keyframes slideOutFromLeft {
  0% {
    transform: translateX(+50%);
  }

  100% {
    transform: translateX(0);
  }
}

  .tableDivMobile .PaginationDiv {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }

  .tableDivMobile #bottomContainer {
  }

  .tableDivMobile #FilterMenu {
    width: 99%;
  }

  @keyframes slideInFromLeftMobile {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(+99%);
    }
  }

  @keyframes slideOutFromLeftMobile {
    0% {
      transform: translateX(+99%);
    }

    100% {
      transform: translateX(0);
    }
  }

.MuiPickersToolbar-toolbar {
  background-color: #005a24 !important;
}

.MuiPickersDay-daySelected {
  background-color: #005a24 !important;
}

.MuiButton-textPrimary {
  color: #005a24 !important;
}

.MuiInput-underline:before {
  border-bottom: 0px !important;
}

.MuiInput-underline:after {
  border-bottom: 0px !important;
}

.dateFilterDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.MuiInputBase-input:hover {
  border: 2px solid black !important;
  background-color: black !important;
  color: white !important;
  border-radius: 5px;
}

.MuiInputBase-input {
  border: 2px solid #EDF2F7 !important;
  background-color: white !important;
  color: #06ac38 !important;
  border-radius: 5px;
}