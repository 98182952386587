@import "../../assets/fonts/fontsInterface.css";
.modal-home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(17 24 39 / 0.5);
  z-index: 1000;
  font-family: "Plain-Regular";
}

.modal-main-home {
  max-height: 96vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  background: white;
  width: 98%;
  top: 50%;
  overflow: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-shadow: 10px 14px 44px 0px #00000040;
}
.modal-title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 125%;
}
.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-button-close-div-home {
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
  align-items: flex-start;
  background-color: transparent;
  color: #fff;
  position: absolute;
  z-index: 10;
  width: 100%;
}

.modal-button-close-div-home-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: transparent;
  color: #000;
  width: 100%;
  margin-bottom: 32px;
}

.modal-button-close-home {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  border-radius: 0.375rem;
  background-color: transparent;
  padding: 0.375rem;
  font-size: 18px;
  border: none;
  color: #fff;
}

#modal-container-home {
  width: auto !important;
  max-width: 100%;
  flex: 1 1 0%;
  overflow: auto;
}
