.selected-dates {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 8px;
}

.rs-picker-popup.rs-picker-popup-date {
  z-index: 1050;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.selected-date-chip {
  background-color: #e8f5e9;
  border: 1px solid #81c784;
  border-radius: 20px;
  padding: 6px 12px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

.selected-date-chip:hover {
  background-color: #c8e6c9;
}

.requiredLabel {
  font-weight: 400;
  color: #757575;
  font-size: 12px;
  margin-left: 4px;
}

.remove-date-btn {
  background: none;
  border: none;
  color: #4caf50;
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
  padding: 2px;
  line-height: 1;
  transition: color 0.3s ease;
}

.remove-date-btn:hover {
  color: #388e3c;
}

.remove-date-btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.4);
}

.premium-support-request__modal-form.modal--service {
  display: grid;
  gap: 16px;
}

@media (max-width: 768px) {
  .selected-dates {
    gap: 8px;
  }

  .selected-date-chip {
    font-size: 12px;
    padding: 4px 10px;
  }

  .remove-date-btn {
    font-size: 14px;
  }
}
