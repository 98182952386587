.product-card {
	background-color: #fff;
	width: 200px;
	text-align: center;
	font-size: 12px;
	height: auto;
	margin-bottom: 10px;
}

.product-name {
    align-items: center;
    background-color: #117170;
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
    color: white;
    display: flex;
    font-size: 12px;
    justify-content: center;
    margin: 0;
    padding: 16px;
}

.licenses {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	padding: 3px 0;
}

.licenses-purchased, .licenses-deployed {
	border-right: 1px solid #eee;
	padding: 0 6px;
	font-size: 12px;
	padding-top: 10px;
    overflow-x: auto;
}
.licenses-purchased h3, .licenses-deployed h3 {
	font-size: 12px;
}


.licenses-deployed {
  border-right: none;
  font-size: 12px;
  border-right: 1px solid #eee;
  overflow-x: auto;
}

.product-type-title {
	padding-bottom: 10px;
	margin-bottom: 5px;
	border-bottom: 1px solid #eee;
	margin-top: -5px;
	font-size: 12px;
}

.product-type-value {
    background-color: #F4F4F4;
    border-bottom: 0px;
    border-radius: 0px 0px 8px 8px;
    color: #343434;
    margin: 0;
    padding: 8px;
}

.scrollSpan {
    margin-right: 10px;
}