#tabsContainer {
  display: flex;
  bottom: -1px;
  position: relative;
}

#tabsClass {
  padding: 10px 16px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0px;
  position: relative;
  display: inline-block;
  font-family: "Plain-Thin";
  font-size: 16px;
  z-index: 1;
  margin-right: 3px;
}

#tabsClassActive {
  padding: 10px 16px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dededf;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0px;
  position: relative;
  display: inline-block;
  font-family: "Plain-Thin";
  font-size: 16px;
  margin-right: 3px;
  z-index: 3;
  font-weight: bold;
}

#tabsConatiner {
  height: fit-content;
  border: 1px solid #06ac38;
  border-radius: 5px;
  border-top-left-radius: 0px;
  background-color: white;
}

#tabsConatinerBottom {
  height: calc(100% - 45px);
  border: 1px solid #dededf;
  border-radius: 5px;
  border-top-left-radius: 0px;
  background-color: white;
  max-height: calc(100% - 28px);
  z-index: 2;
  width: fit-content;
}

#tabsConatinerBottom .TableContainer {
  border-radius: 0px;
}

#tabsConatinerBottom .TableContainerOpen {
  border-radius: 0px;
}

#tabsConatinerBottom .TableContainerNoAnimation {
  border-radius: 0px;
}

#tabsConatinerBottom #FilterMenuContainer {
  background-color: #dfdfdf;
}

#tabsConatinerBottom #FilterMenu {
  background-color: #dfdfdf;
}
