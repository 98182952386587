.InputContainer {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  padding-top: 0px;
  flex-grow: 1;
  justify-content: center;
  border: 1px solid hsl(0,0%,80%);
  background-color: white;
}

.InputContainerInner {
  display: flex;
  align-items: center;
  width: 300px;
  overflow-x: auto;
  flex-grow: 1;
  padding-left: 8px;
  background-color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.Tag {
  color: #fff;
  background-color: #06ac38;
  padding: 3px 3px 3px 10px;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 85%;
  overflow: hidden;
  max-width: 150px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.TagIcon {
  color: #fff;
  background-color: #06ac38;
  padding: 3px 5px 3px 5px;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: 0px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.TagIcon:hover {
  background-color: #fff;
  color: #06ac38;
  border: 1px solid #06ac38;
}

.InputIcon1 {
  color: hsl(0, 0%, 80%);
  padding: 4px;
  margin: 5px;
}

.InputIcon1:hover {
  color: hsl(0, 0%, 60%);
}

.InputIcon2 {
  color: #cccccc;
  padding: 4px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-left: 1px solid #cccccc;
}

.InputIcon2:hover {
  color: hsl(0, 0%, 60%);
}

.InputInput {
  border: 0px;
  outline: none;
  flex-grow: 1;
  min-width: 100px;
  text-align: start;
  margin: 0px;
}

.Flex {
  display: flex;
  flex: auto 0 0;
}

.MultiSelectBody {
  z-index: 10000;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-top: 5px;
  margin-left: 10px;
  position: absolute;
  background-color: white;
  width: calc(100% - 30px);
  max-height: 300px;
  overflow-y: scroll;
}

.MultiSelectNoOptions {
  z-index: 10000;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-top: 5px;
  position: absolute;
  background-color: white;
  width: calc(100% - 30px);
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MultiSelectBody ul {
  list-style-type: none;
  padding: 0px;
}

.MultiSelectBody ul li {
  padding: 5px 10px;
}

