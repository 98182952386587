.container__solutionGallery {
  border-radius: 5px;
  text-align: left;
  font-family: "Plain-Regular";
  display: flex;
  flex-direction: column;
  gap:10px
}
.solutionGallery__toggle {
  position: relative;
  width: 100px;
  height: 34px;
}
.container__toggleAndAddSolutionGallery {
  display: flex;
  justify-content: end;
  column-gap: 30px;
  flex-wrap: wrap;
}
.container__searchSolutionGallery {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
}
.searchSolutionGallery__input:focus {
  outline: none;
}
.searchSolutionGallery__input {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid  #E9E9E9;
  width: 100%;
  padding-left: 34px;
}
.container__iconGlass {
  position: absolute;
  top: 18px;
  left: 10px;
  color: #878787

}
.toggleSolution__slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #002b4f;
  border-radius: 34px;
  cursor: pointer;
  transition: 0.4s;
}
.toggleSolution__slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}
.input__checkbox[type='checkbox'] {
  display: none;
}
.input__checkbox[type='checkbox']:checked + .toggleSolution__slider {
  background-color: #bdc1bf;
}
.input__checkbox[type='checkbox']:checked + .toggleSolution__slider:before {
  transform: translateX(69px);
}
.solutionGallery__header {
  display: flex;
  column-gap: 40px;
  align-items: center; 
  flex-wrap: wrap;
  justify-content: space-between;
}
.header__section {
  width: 25%;
}
.toggle__active {
  text-align: end;
  margin-right: 10px;
  color: #fff;
  font-size: 15px;
  margin-top: 5px;
}
.toggle__disabled {
  margin-top: 5px;
  margin-left: 7px;
  color: #fff;
  font-size: 15px;
}
.container__cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 15px;
  column-gap: 64px;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  cursor: pointer;
}
.card {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: none;
  width: 300px;
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.header__title {
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: 31px;
  font-weight: bold;
  color: #117170;
  margin-bottom: 30px;
  margin-top: 10px;
}
.container__copy {
 margin-bottom: 50px;
}
.card__footer {
  display: flex;
  column-gap: 26px;
  font-size: 20px;
  color: #002b4f;
  padding: 2px 20px 6px;
  align-items: center;
  border-top: 1px solid #fff;
  margin-top: auto;
  justify-content: end;
}
.footer__bottom {
  display: flex;
  justify-content: end;
  width: 100%;
  column-gap: 20px;
}
.card__header {
  color: #fff;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%; /* Centrado horizontal */
  transform: translate(-50%, -50%); 
  width: 100%;
}

.input__containerSolutionGallery {
  position: relative;
  flex: 3;
}
.icon__containerSearch {
  position: absolute;
  top: 50%;
  right: 10px; 
  transform: translateY(-50%);
  cursor: pointer;
  color: #002b4f;
}







.container__headerSolution {
  font-size: 18px;
  color: #ffff;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: 0 0 12px #d7d7d7;
  padding: 15px;
  background-image: url('../../assets/wallpaper1.png');
  background-repeat: no-repeat;
  background-size: cover;
  flex-wrap: wrap;
}
.header__sliderText {
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
}
.container__cardSlider .cardSolution {
  width: calc(33.3% - 16px);
}
.container__sliderText {
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 12px #d7d7d7;
  margin-top: 10px;
  padding: 24px;
}
.container__imgSolutionCard {
  position: relative;
}
.container__topCard{
  color: #fff;
  border: 1px solid #ffff;
  padding: 5px;
  border-radius: 8px;
  background: #005A24;
}
.container__faInfoCircle{
  position: absolute;
  top:15px;
  right: 15px;
  color: #ffff;
}
.img__pageyUser {
  height: 100px;
  right: -11px;
  position: absolute;
}
.container__imgUser {
  position: relative;
  height: 100px;
  width: 100px;
  background-color: #117170;
  border-radius: 50%;
  border: 10px solid white;
  overflow: hidden;
  box-shadow: 0px 0px 5px -1px black;
}
.container__hoursLeft {
  position: relative;
  height: 76px;
  width: 185px;
  background-color: #048a24;
  border-radius: 5px;
  border: 5px solid white;
  overflow: hidden;
  box-shadow: 0px 0px 5px -1px black;
  right: 16px;
  top: 15px;
}
.hoursLef__title {
  position: relative;
  font-size: 16px;
  top: 7px;
  left: 7px;
  font-weight: bold
  /* position: relative;
  font-size: 16px;
  top: 19px;
  left: 4px;
  font-weight: bold; */
}
.hoursLef__subTitle {
  position: relative;
  font-size: 16px;
  top: 19px;
  left: 32px;
} 
.container__circle {
  display: flex;
}
.header__text {
  margin-bottom: 5px;
}
.card__solution {
  max-width: 300px;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin: 1rem;
  box-shadow: 0 0 12px #d7d7d7;
}
.card__solutionTop {
  max-width: 300px;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin: 1rem;
  box-shadow: 0 0 12px #d7d7d7;
  display: flex;
  flex-direction: column;
}
.container__footerTop {
  margin-top: auto; 
  padding: 1rem;
}
.img__card{
  max-width: 100%;
  border-radius: 8px 8px 0 0;
  max-height: 150px;
  height: 150px;
  width: 100%;
}
.body__card {
  padding: 1rem;
}
.card__content {
  display: flex;
  flex-flow: wrap;
}
.name__card {
  color: #002b4f;
  font-size: 18px;
  font-weight: bold;
} 
.card__nameAndType {
  display: flex;
  font-size: 15px;
  color: #3f3f3f;
  margin-top: 5px;
}
.description__card {
  font-size: 14px;
  font-weight: 375;
  line-height: 21px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  color: #313131;
}
.footer__card {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.footer__targetHour {
  display: flex;
  align-items: center;
  width: 100%;
}
.card__addCar {
  border-radius: 7px;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #002b4f;
  box-shadow: 0 0 0 1px #002b4f, 0 0 0 0px #fff;
  cursor: pointer;
  height: 40px;
  width: 25%;
  text-align: center;
}
.card__addCar:hover {
  background-color: #048a24;
  color: #fff;
  box-shadow: 0 0 0 1px #048a24, 0 0 0 0px #fff !important;
  border: none !important;
}
.card__addCarTop:hover {
  background-color: #048a24;
  color: #fff;
  box-shadow: 0 0 0 1px #048a24, 0 0 0 0px #fff !important;
  border: none !important;
}
.footer__targetHour {
  color: #3f3f3f;
}
.container__sliderSolution {
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.container__cardSlider {
  display: flex;
  width: 100%;
  gap: 24px;
}
.btn__sliderSolution {
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 6px;
  width: 30px;
  margin: 5px;
  border: none;
  background-color: transparent;
  color: rgba(96, 75, 75, 0.291);
  font-size: 22px;
}
.btn__sliderSolution:disabled {
  cursor: not-allowed;
}
.btn__sliderSolution:not(:disabled){
  color: rgb(4, 138, 36);
}
.card__type {
  border-left: 1px solid #c6c0c0;
  margin-left: 10px;
  padding-left: 10px;
}
.btn__sliderSolution:hover {
  color: rgb(108, 117, 125) !important;
}
.btn__sliderSolution:disabled {
  color:  rgba(96, 75, 75, 0.291) !important;
}
.btn__sliderSolution:disabled:hover {
  color: rgba(96, 75, 75, 0.291) !important; 
}
.container__filter {
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 12px #d7d7d7;
  margin-top: 10px;
  padding: 20px;
}
.container__selectGallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 5px;
  margin-top: 20px;
}
.selectOption__gallery {
  width: 100%;
  background-color: #ffff;
  color: black;
  margin: 0;
  text-align: start;
  margin-top: 5px;
  border-radius: 5px;
  padding: 9px;
}
.container__selectGallery > div {
  flex: 1; 
  /* margin-right: 10px; */
}
.text__containerSearch {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;

}
.title__select {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
  
}
.container__cardAndFilter {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 12px #d7d7d7;
}
.container__cardAndFilter  {
  flex-basis: 27%;
  margin-right: 20px;
  width: 100%;
}
.container__refineResults {
  width: 25%;
}
.container__cardHorizontal  {
  width: 74%;
  margin-left: 10px;
}
.cardHorizontal__container {
  display: flex;
  width: 100%;
  height: auto;
  background-color: #ffff;
  border-radius: 8px;
  box-shadow: 0 0 12px #d7d7d7;
  flex-wrap: wrap;
  padding: 7px;
  margin-bottom: 6px;
}
.img__cardHorizontal {
  max-width: 100%;
  height: 206px;
  max-height: 206px
}
.header__cardHorizontal {
  flex: 0 0 30%; 
}
.body__cardHorinzontal {
  flex: 1;
  padding: 0 20px; 
}
.container__paginationSolution {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container__totalAndClear {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}
.showing__total {
  color: #656464;
}
.clear__selection {
  color:  #048a24;
  cursor: pointer;
}
.clear__selection:hover  {
  color:  #053510;;
}
.container__nameAndInfo {
  display: flex;
  justify-content: space-between;
}
.icon__faInfoCircle {
  color: #002b4f
}
.container__refineResults {
  border: 1px solid #cdcccc ;
  border-radius: 5px;
  background-color: #fff;
}
.tittle__refineResults {
  border-bottom: 1px solid #cdcccc;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  background-color: #048a24;
}
.container__resultsFilter {
  padding: 20px 10px;
  height: auto;
  background-color: #ffff;
  /* box-shadow: 0 0 12px #d7d7d7; */
  height: 100%;
}
.filter__title{
  font-weight: bold;
  font-size: 15px;
}
.results__filter {
  margin-bottom: 10px;
}
.container__itemFilter {
  display: flex;
  margin-top: 10px;
}
.input__az,.input__za {
  margin: 0px 
}
.icon__az, .icon__za {
  margin-left: 10px;
}
.input__checkbox[type='checkbox']{
  display: flex;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}
.text__checkbox {
  margin-left: 10px;
}
.container__detail{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(17 24 39 / 0.5);
  z-index: 1000;
}
.container__section{
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  background: white;
  width: 40%;
  top: 50%;
  overflow: auto;
  max-height: 90vh;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.detail__header {
  padding: 16px 24px 16px 24px;
  display: flex;
  align-items: center;
  gap: 16px;
}
.modal__containerDetail {
  padding: 0px;
}
.container__domainAndHour{
  display: flex;
  background-color: #117170;
  color: #fff;
  border-radius: 8px;
}
.solutionDetail {
  background-color: #ACF07D;
  padding: 5px;
  box-shadow: 5px 5px 5px rgba(0, 128, 0, 0.6)
  /* box-shadow: 5px 5px 5px rgba(0, 0,0, 6) */
}
.domain, .hour {
  padding: 4px 8px;
}
.hour {
  border-left: 1px solid #C6C0C0;
}
.detail__categoryName {
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  align-items: flex-start;
  border-bottom: 1px solid #EFEFEF;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #343434;
  font-size: 20px;
  background: none;
  & svg {
    cursor: pointer;
  }
}
.container__why{
  margin: 24px;
}
.container__subHeader{
  padding: 16px 24px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.why__title{
  background-color: #ACF07D;
  /* box-shadow: 5px 5px 5px rgba(0, 128, 0, 0.6); */
  padding: 4px 10px 2px 10px;
  position: relative;
}
.why__title::after {
  content: '';
  position: absolute;
  top: -2%;
  right: -13px;
  width: 0;
  height: -11px;
  border-top: 16px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 14px solid #ACF07D;
  height: 31px;
}
.subtitle__activities {
  margin-top: 10px;
}
.why__ul {
  list-style-type: circle;
  padding: 10px 20px 2px 20px;
  font-size: 13px;
}
.why__li {
  margin-bottom: 2px;
}
.container__whatDoes {
  padding: 0px 25px 25px 25px;
}
.solution__description {
  font-size: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.icon__faInfoCircle:hover {
  color: #086405;
  cursor: pointer;
}
.footer__detail {
  background-color: #086405;
}
.logo__footer {
  color: #ffff;
  width: 180px;
  padding: 10px 25px 10px 25px;
}
.go__back {
  margin-right: 15px;
}

.container__buttonFilter {
  display: flex;
  column-gap: 4px;
  overflow-x: scroll;
  max-width: 600px;
}
.container__paginationSolution >.pagination__solutionGallery {
  background-color: rgb(248 248 248) !important;
  border: none;
}


@media (max-width: 1280px) {
  .container__cardSlider {
    scroll-behavior: smooth;
    transition: scroll 0.3s ease-in-out;
    overflow-x: auto;
  }
  .container__sliderSolution {
    width: 80%;
  }
  .container__cardAndFilter > div {
    flex-basis: 100%; 
    margin-bottom: 10px; 
  }
}
.dropdown__solutionCategories_solutionGallery {
  background-color: #fff;
  border: 1px solid #ccc;
  height: 300px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(17, 24, 39, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
  list-style: none;
  margin: 0;
  overflow: scroll;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.dropdown__solutionCategories_solutionGallery li {
  padding: 8px 12px;
}
.container__solutionCategories {
  margin-top: 7px;
}
.dropdown-toggleButtonSolutionCategories {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  padding-top: 0px;
  flex-grow: 1;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: white;
  justify-content: space-between;
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-left: 8px;
  background-color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 100%;
  height: 43px;
  padding-right: 10px;
  transition: opacity 0.2s ease;
}

.dropdown-toggleButtonSolutionCategories::after {
  display: inline-block;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
}
.item__filter {
  color: #fff;
  background-color: #06ac38;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 85%;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  padding: 3px;
}
.dropdown__date {
  padding: 8px 12px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #002b4f;
  border-radius: 5px;
	width: 100%;
	text-align: left;
  height: 42px;
  margin-top: 6px;
}
.multiSelect__filterSolutionGallery {
  width: 100%;
  position: relative;
}

.product__added {
  float: inline-end;
  margin-top: 11px;
  color: #048a24;
  margin-bottom: 10px;
}
.not__data {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 20vh;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}
.select__theOperations {
  display: flex;
  justify-content: center;
}
.solution_target {
  width: 117px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.current__future {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10px;
  margin: 0 20px 20px;
  row-gap: 10px;
}
.item__current__future {
  margin-bottom: 10px;
}
.container__currentFuture {
  border-radius: 16px;
  padding: 16px;
  text-align: left;
  width: 49%;
}
.title__currentFuture {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}
.next_best {
  margin-top: 10px;
  /* border-bottom: 1px solid; */
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
}
.solution_targetTop {
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card__addCarTop {
  border-radius: 7px;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid #002b4f;
  box-shadow: 0 0 0 1px #002b4f, 0 0 0 0px #fff;
  cursor: pointer;
  width: 42%;
  margin-top: 10px;
  text-align: center;
}
.container__cardTop {
  display: flex;
  justify-content: end;
}
.future {
  border-left: 2px solid #ACF07D;
  background-color: #fafafa;
}
.current {
  border-left: 2px solid #FFD866;
  background-color: #fafafa;
}
.container__textWelcome {
  width: 70%;
}
.container__typicalActivities {
  margin-top: 15px;
}
.welcome__textSolution {
  font-size: 18px;
  font-weight: 600;
}
.not__sra {

}

@media screen and (min-width: 2560px) {
  .dropdown__solutionCategories_solutionGallery {
    width: 72%;
  }
}


.container__hoursLeftHeader {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.container__welcomeSolutions {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 80%;
  border-right: 1px solid  rgba(187, 187, 187, 1);
  padding-left: 13px;
}
.hours__left {
  color: rgba(52, 52, 52, 1);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.container__sliderSolution {
  margin-top: 50px;
  width: 100%;

}
.container__cardSolution {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 24px;
  align-items: stretch;
  

}
.infoContainer__header {
  display: flex;
  gap:8px;
  font-size: 11px;
  text-transform: uppercase;
}
.infoContainer_content {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.image-section img {
  width: 100%;
  height: auto;
}
.infoContainer__typeSolution {
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  background:  rgba(17, 113, 112, 1);
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.infoContainer__totalHours {
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  background: rgba(229, 242, 233, 1);
}
.solution__infoContainer {
  display: flex;
  gap:16px;
  flex-direction: column;
}
.icon__time {
  margin-right: 10px;
}

.infoContainer__bodySolution {
  display: grid;
  grid-template-columns: 93px 1fr;
  gap:15px;
}
.infoContainer__titleBodySolution {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  margin-bottom: 10px;
}
.infoContainer__footerSolution {
  display: flex;
  justify-content: flex-end;
  gap:16px
}
.footerSolution__viewDetails {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: rgba(91, 91, 91, 1);
  padding: 8px;
  cursor: pointer;
}
.footerSolution__addToCart {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid  rgba(4, 138, 36, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: rgba(4, 138, 36, 1);
  cursor:pointer
}
.container__secondSolution {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.vertical__cardSolution{
  padding: 24px;
  border-radius: 16px;
  background-color: rgba(250, 250, 250, 1);
  border-top: 2px solid #688776;
  height: 100%;
  overflow-wrap: anywhere;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container__descriptionSolution {
  text-wrap: auto;
}
.container__buttonSearchSolution {
  display: flex;
  justify-content: end;
  margin-top: 20px;
  gap:24px;
  align-items: center;
  margin-bottom: 20px;
}
.button__searchSolution {
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  background:  #048A24;
  cursor: pointer;
  border: none;
  color: #fff;
}
.clear__fieldsSolution{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #5B5B5B;
  cursor: pointer;
}
.container__paginationSolution {
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
  margin-top: 42px;
}
.button__searchSolution:disabled {
  opacity: 0.2;
}
.button__selectedItem {
  border: none;
  background: none;
}
.button__selectedItem:disabled {
  opacity:  0.2;
} 
.container__refine {
  display: flex;
  gap:15px;
  align-items: center;
}
.refine__results {
  padding: 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid  #535353;
  cursor: pointer;
}





.filter__container {
  position: relative;
  display: inline-block;
}

.filter__button {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid  #535353;
  cursor: pointer;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.filter__icon {
  font-size: 16px;
}

.filter__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
  z-index: 10;
  width: 240px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown__section {
  margin-bottom: 16px;
}

.dropdown__section p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.dropdown__section label {
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  cursor: pointer;
}

.dropdown__section input[type="radio"] {
  margin-right: 8px;
}
.container__copySolution{
  margin-top: 10px;
}