.notification-container {
  z-index: 10000000;
  bottom: 1.5rem;
  position: fixed;
  right: 1.5rem;
  gap: 12px;
  pointer-events: auto;
  place-content: center;
  grid-template-columns: 1fr;
  .notification {
    display: grid;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 10px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    max-width: 24rem;
    width: 100%;
    .notification-icon__container {
      flex-shrink: 0;

      svg {
        width: 1.25rem;
        height: 1.25rem;
        display: block;
        vertical-align: middle;

        &.notification-icon__info {
          color: #60a5fa; 
        }
      
        &.notification-icon__warning {
          color: #fbbf24; 
        }
      
        &.notification-icon__error {
          color: #ef4444; 
        }
      }

    }

    .notification-message__container {
      padding-top: 0.125rem;
      flex: 1 1 0%;
      margin-left: 0.75rem;

      .notification-message__title {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: rgb(17 24 39 /1);
      }
    }
}

  .notification-button__container {
    display: flex;
    margin-left: 1rem;
    flex-shrink: 0;
    .notification-button {
      border: 0 solid rgb(229, 231, 235);
      border-radius: 0.375rem;
      display: inline-flex;
      cursor: pointer;
      color: rgb(156 163 175 /1);
      background-color: rgb(255 255 255 / 1);
      background-image: none;
      text-transform: none;
      font-family: inherit;
      font-size: 100%;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
      margin: 0;
      padding: 0;
    }
    .notification__close {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }
    .notification-button__icon{
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

