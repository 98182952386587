.horizontal__card {
  align-items: center;
  background: rgba(250, 250, 250, 1);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 31.4%;
}
.HorizontalCardIcon {
  align-items: center;
  background: #E9E9E9;
  border-radius: 50%;
  display: flex;
  height: 48px;
  padding: 12px;
  width: 48px;
}
.title__card {
  color: #343434;
  font-weight: 600;
}
.horizontal__card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}