.container__authorizationModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(17 24 39 / 0.5);
	z-index: 1000;
	font-family: 'Plain-Regular';
}
.container__info {
	display: flex;
	flex-direction: column;
	position: fixed;
	background: white;
	width: 30%;
	top: 50%;
	overflow: auto;
	height: 378px;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 0.5rem;
	--tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	--tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.container__imgPageyUseAuthorization{
	background-color: rgb(6, 172, 56);
	height: 25%;
}
.imgPageyUseAuthorization {
	position: relative;
	height: 140px;
	width: 140px;
	background-color: #117170;
	border-radius: 50%;
	border: 10px solid white;
	overflow: hidden;
	margin: 20px 0px;
	box-shadow: 0px 0px 5px -1px black;
	left: 50%;
	transform: translate(-50%, -50%);
	top: -8%;
 }
 .img__authorization {
	height: 129px;
	right: -5px;
	position: absolute;
 }
 .container__messageText { 
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	top: 65%; 
	text-align: center;
	width: 80%;
}
.expand__session {
	margin-left: 10px;
}
.container__btns {
	display: flex;
	justify-content: center;
}
.btn__logIn { 
	border: 1px solid rgb(6, 172, 56);
	border-radius: 5px;
	background-color: rgb(6, 172, 56);
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	padding-left: 20px;
	padding-right: 20px;
	height: 40px;
	width: 100%;
	margin-top: 25px;
 }