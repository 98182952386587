
@import "../../assets/fonts/fontsInterface.css";
#Deck-generator-container {
  margin: 0px 10px 10px 10px;
  font-family: 'Plain-Regular';
  padding: 0  1.5em;

  .form-input__container{
    display: flex;
    justify-content: flex-start;
  }

  .card-dashboard-fit {
    height: fit-content;
    background-color: white;
    padding: 20px;
    border-top: 1px solid #dededf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

@media (max-width: 1040px) {
  .form-input__container {
    flex-direction: column;
  }

  .dropdown-deck__container {
    flex-direction: column;
  }
}

#Deck-generator-title-container {
  display: flex;
  padding-bottom: 10px;
  width: 100%;
  justify-content: space-between;
}
#Deck-generator-title {
  font-size: 30px;
  margin: 0;
  margin-left: 10px;
  margin-top: 5px;
    color: #117170;
}


#DeckGenerator-FAQ {
  display: flex;
  justify-content: end;
  }
  
  #DeckGenerator-FAQIcon {
  cursor: pointer;
  color: #015A23;
  font-size: 16px;
  margin-left: 10px;
  }

@media (min-width: 700px) {
  .wrapper {
    overflow: hidden;
  }
  .wrapper > * {
    float: left;
    padding: 2em 2em;
  }
}

@media (min-width: 700px) {
  .contacts {
    width: 30%;
    min-height: 620px;
  }
}

@media (min-width: 700px) {
  form #deck-form {
    width: 100%;
  }
}

form #deck-form {
  float: left;
  max-width: 500px;
  margin-bottom: 10px;
  label {
    margin-bottom: 10px;
  }
}
form #deck-form:not(:nth-child(2n)) {
  margin-right: 1em;
}

form #deck-form:last-child {
  clear: both;
  width: 100%;
}

.info-message {
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 20px;
  color: #048a24;
  font-weight: bold;
}

.beta-tag{
  display: flex;
  margin-left: 20px;
  background-color: #048a24;
  padding: 3px;
  border-radius: 4px;
  color: white;
}


/**************************************/


form {
  display: flex;
  flex-direction: column;
}

.wrapper {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.wrapper > * {
  padding: 2em;
}

ul {
  list-style: none;
  padding: 0;
}

.deck-generator-title {
  font-weight: 500;
  margin-left: 10px;
  font-size: 16px;
}
.contain{
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.contacts {
  background: #4b5195;
  color: #fff;
}
.form {
  text-align: start;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
  0 2px 1px -1px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 5px;
  padding: 25px 25px;
}
form label {
  display: block;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #333; /* Adjust to the exact color from the layout */
}

.deck-generator {
  width: 100%;
  border: 1px solid #CCC;
  border-radius: 5px;
  color: #000;
  text-align: start;
}

input:disabled {
  background-color: #eee;
  cursor: not-allowed;
  color: rgb(17 24 39 / 1);
  opacity: 0.5;
}

.dropdown-deck__container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  .dropdown-deck {
    -webkit-appearance: none; /* Removes default styling on Webkit browsers */
    -moz-appearance: none;    /* Removes default styling on Mozilla browsers */
    appearance: none;         /* Removes default styling on the rest */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    /* Add custom arrow */
    background-image: url('/src/assets/SVGs/arrow.svg');
    background-position: right 10px center; /* Adjust the position as needed */
    background-repeat: no-repeat;
    background-size: 12px;
    margin-left: 10px;
  }
}
.deck-generator {
  padding: 10px;
}

 .deck-generator{
  background-color: #fff;
}

textarea, .deck-generator{
  margin-left: 10px;

}

.deck-generator-multi label {
  margin-bottom: 10px;
}

.deck-generator-multi .InputContainer {
  margin-left: 10px;
  width: 100%;
}

.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-left: 10px;
}

.deck-generator-button {
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  padding: 15px 25px; /* Adjusted padding for larger size */
  border: none;
  background-color: #048a24;
  color: white;
  border-radius: 5px;
  font-size: 16px; /* Slightly larger font size for the button */
  margin-left: 10px;
  display: block;
  width: 100%; /* Makes the button take the full width of its container */
  text-align: center; 
  width: 200px;
}

.deck-generator-button:hover {
  background-color: #015A23;
}

.deck-generator-button {
  color: #fff;
  cursor: pointer;
  padding: 15px 25px; /* Adjusted padding for larger size */
  border: none;
  background-color: #048a24;
  color: white;
  border-radius: 5px;
  font-size: 16px; /* Slightly larger font size for the button */
  margin-left: 10px;
  display: block;
  width: 100%; /* Makes the button take the full width of its container */
  text-align: center; 
  width: 200px;
}

.deck-generator-button:hover {
  background-color: #015A23;
}

.deck-generator-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.deck-tour-button {
  padding: 10px 20px;
border-radius: 5px;
outline: none;
margin-right: 15px;
border: 1px solid #afafaf;
cursor: pointer;
background-color: #048a24;
color: white;

&:hover {
  background-color: #ffffff;
    color: #048a24;
  }
}

.tag {
  color: #fff;
  background-color: #117170;;
  padding: 3px 3px 3px 10px;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 85%;
}

.tag-icon {
  color: #fff;
  background-color: #117170;;
  padding: 3px 5px 3px 5px;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: 0px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.tag-icon:hover {
  background-color: #fff;
  color: #117170;;
  border: 1px solid #117170;;
}

.Flex {
  display: flex;
  flex: auto 0 0;
}

.email-input__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  flex-grow: 1;
  margin-left: 10px;
  background-color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 100%;
  .email-input__domain {
    border-radius: 0.375rem;
    display: flex;
    flex-grow: 1;
    .email-domain {
      padding: 10px;
      display: inline-flex;
      align-items: center;
      border: 1px solid #D1D5DA;
      border-left-width: 0px;
      background-color: #117170;
      color: #eee;
      border-top-right-radius: 0.375rem;
      border-bottom-right-radius: 0.375rem;
    }
    input.email-input {
      flex-grow: 1;
      min-width: 0;
      border-top-left-radius: 0.375rem;
      border: 1px solid #D1D5DA;
      border-bottom-left-radius: 0.375rem;
    }
  }
  .input-close {
    color: #fff;
    background-color: #117170;;
    padding: 10px;
    border-radius: 5px;
    margin-top: 3px;
    margin-left: auto;
    margin-bottom: 3px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    flex-shrink: 0;
  }
  .input-close:hover {
    background-color: #fff;
    color: #117170;;
    border: 1px solid #117170;;
  }
}
.dropdown-deckSelect {
  appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  background-image: url('/src/assets/SVGs/arrow.svg');
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 12px;
  margin-left: 10px;
}
.message__deck {
  color: #06ac38;
  margin-left: 10px;
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
}
.spinner__deck {
  height: 20% !important;
}